import { Button, Input, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productActions } from "../../redux/slices/productSlice";
import { editPricingApi } from "../../redux/apis/dashboardApi";

const ByCategory = ({ loggedUser }) => {
  const dispatch = useDispatch();

  const [categoryData, setCategoryData] = useState([]);
  const [changedPrice, setChangedPrice] = useState({
    inc: null,
    desc: null,
  });

  const categoryListData = useSelector(
    (state) => state?.products?.categoryList
  );

  useEffect(() => {
    if (loggedUser) {
      dispatch(
        productActions.categoryList({ funnelId: loggedUser?.activeFunnel?._id })
      );
    }
    // eslint-disable-next-line
  }, [loggedUser]);

  useEffect(() => {
    if (categoryListData) {
      let newArr = [];

      // eslint-disable-next-line
      categoryListData?.map((val, i) => {
        newArr.push({
          category: val?.category,
          inc: val?.incPrice || 0,
          desc: val?.descPrice || 0,
          price: val?.price,
        });
      });

      setCategoryData(newArr);
    }
  }, [categoryListData]);

  const columns = [
    {
      title: "Product Category",
      dataIndex: "category",
      width: "25%",
    },
    {
      title: "Increase By %",
      dataIndex: "inc",
      width: "25%",
      render: (val, elm) => {
        return (
          <>
            <div style={{ display: "flex", gap: "10px" }}>
              <Input
                className="hide-arrows"
                placeholder="Please enter"
                style={{ width: "100%" }}
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                max={100}
                onChange={(inputVal) => {
                  setChangedPrice({
                    ...changedPrice,
                    inc: inputVal.target.value,
                  });
                }}
              />
              <Button onClick={() => handleSave("inc", elm)} type="primary">
                Save
              </Button>
            </div>
          </>
        );
      },
    },
    {
      title: "Decrease By %",
      dataIndex: "desc",
      width: "25%",
      render: (val, elm) => {
        return (
          <>
            <div style={{ display: "flex", gap: "10px" }}>
              <Input
                placeholder="Please enter"
                style={{ width: "100%" }}
                max={100}
                onChange={(inputVal) =>
                  setChangedPrice({
                    ...changedPrice,
                    desc: inputVal.target.value,
                  })
                }
                className="hide-arrows"
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
              <Button onClick={() => handleSave("desc", elm)} type="primary">
                Save
              </Button>
            </div>
          </>
        );
      },
    },
  ];

  const handleSave = async (type, row) => {
    console.log(row);
    try {
      let submitData = null;

      if (changedPrice.inc || changedPrice.desc) {
        if (type === "inc") {
          submitData = {
            type: "inc",
            funnelId: loggedUser?.activeFunnel?._id,
            category: row.category,
            incPrice: changedPrice.inc,
            descPrice: changedPrice.desc,
            price: Number(row.price),
          };
        } else {
          submitData = {
            type: "desc",
            funnelId: loggedUser?.activeFunnel?._id,
            category: row.category,
            incPrice: changedPrice.inc,
            descPrice: changedPrice.desc,
            price: Number(row.price),
          };
        }
      }

      const res = await editPricingApi(submitData);

      if (res?.data?.success) {
        message.success(res?.data?.message);
        dispatch(
          productActions.categoryList({
            funnelId: loggedUser?.activeFunnel?._id,
          })
        );
      } else {
        message.error(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
      message.error(
        error?.response?.data?.message ??
          "Something went wrong while saving data."
      );
    }
  };

  return (
    <>
      <div>
        <Table dataSource={categoryData} columns={columns} pagination={false} />
      </div>
    </>
  );
};

export default ByCategory;

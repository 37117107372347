import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddEditCustomer from "../../sections/CustomerManage/AddEditCustomer";
import { Card, Form, message } from "antd";
import { PATH_DASHBOARD } from "../../routes/path";
import { addCustomerApi } from "../../redux/apis/dashboardApi";

const AddCustomer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const [addEditCustomerForm] = Form.useForm();

  useEffect(() => {
    if (location) {
      if (location.pathname.includes("edit")) {
        setIsEdit(true);
      } else {
        setIsEdit(false);
      }
    }
  }, [location]);

  const handleCancle = () => {
    navigate(PATH_DASHBOARD.customer);
  };

  const onFinish = async (values) => {
    try {
      setIsSubmit(true);
      const isNewProject = values?.isNewProject ? true : false;

      const data = {
        ...values,
        isNewProject,
        isActive: true,
      };

      const addRes = await addCustomerApi(data);

      if (addRes.data.success) {
        message.success(addRes.data.message);
        setIsSubmit(false);
        setTimeout(() => {
          navigate(PATH_DASHBOARD.customer);
        }, 1000);
      } else {
        setIsSubmit(false);
        message.error(addRes.data.message);
      }
    } catch (error) {
      setIsSubmit(false);
      message.error(error?.response.data?.message);
    }
  };

  return (
    <>
      <Card title="Add Customer">
        <div className="d-flex flex-column justify-content-center h-100">
          <div className="mt-4">
            <Form
              name="addEditCustomerForm"
              layout="vertical"
              form={addEditCustomerForm}
              onFinish={onFinish}
            >
              <AddEditCustomer
                isEdit={isEdit}
                isSubmit={isSubmit}
                handleCancle={handleCancle}
              />
            </Form>
          </div>
        </div>
      </Card>
    </>
  );
};

export default AddCustomer;

import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export const registerApi = (data) => {
  return axios.post(`${baseUrl}/auth/register`, data, {
    withCredentials: true,
  });
};

export const loginApi = (data) => {
  return axios.post(`${baseUrl}/auth/login`, data, { withCredentials: true });
};

export const isLoggedInApi = () => {
  return axios.get(`${baseUrl}/auth/isLoggedIn`, { withCredentials: true });
};

export const logOutApi = () => {
  return axios.get(`${baseUrl}/auth/logout`, { withCredentials: true });
};

export const sendOtpApi = (data) => {
  return axios.post(`${baseUrl}/auth/send-otp`, data, {
    withCredentials: true,
  });
};

export const verifyOtpApi = (data) => {
  return axios.post(`${baseUrl}/auth/verify-otp`, data, {
    withCredentials: true,
  });
};

export const verifyEmailApi = (data) => {
  return axios.post(`${baseUrl}/auth/verify-email`, data);
};

export const forgotPasswordApi = (data) => {
  return axios.post(`${baseUrl}/auth/forgot-password`, data);
};

export const termsAndPrivacyApi = (data) => {
  let str = "?";

  if (data?.type === "terms") {
    str += "type=terms";
  }

  if (data?.type === "privacy") {
    str += "type=privacy";
  }

  if (data?.type === "about") {
    str += "type=about";
  }

  return axios.get(`${baseUrl}/cms` + str);
};

export const helpAndSupportListApi = () => {
  return axios.get(`${baseUrl}/faqs/list`);
};

export const inquiryFormPostApi = (data) => {
  return axios.post(`${baseUrl}/inquiry/add`, data);
};

export const contactUsPostApi = (data) => {
  return axios.post(`${baseUrl}/contact-us/add`, data);
};

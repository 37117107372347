import React, { useState, useEffect } from "react";
import PricingStructurePng from "../../assets/images/pricing-structure.png";
import { Col, Divider, Input, Row, Tooltip, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  ArrowLeftOutlined,
  CheckCircleFilled,
  PauseOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
  MinusCircleTwoTone,
} from "@ant-design/icons";
import { PATH_AUTH } from "../../routes/path";
import { useNavigate } from "react-router";
import {
  selectSubcription,
  subcriptionActions,
} from "../../redux/slices/subcriptionSlice";
import { RightHandIcon } from "../../assets/svgs/icons";

const initialState = {
  price: 0,
  GST: 18,
  noOfLicense: 1,
  setupCost: 10,
  isCoupon: false,
  couponCode: null,
  couponAttempt: 1,
  discountPrice: null,
};

const PricingStructure = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const subcriptionData = useSelector(selectSubcription);

  const [state, setState] = useState(initialState);

  useEffect(() => {
    dispatch(subcriptionActions.subcription());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (subcriptionData) {
      setState({
        ...state,
        price: subcriptionData?.viewData?.perUserPrice || 0,
      });
    }
    // eslint-disable-next-line
  }, [subcriptionData]);

  const handlePlusValue = () => {
    setState({
      ...state,
      noOfLicense: state?.noOfLicense + 1,
      couponCode: null,
      discountPrice: null,
      isCoupon: false,
      couponAttempt: 1,
    });
  };

  const handleMinusValue = () => {
    if (state?.noOfLicense > 1) {
      setState({
        ...state,
        noOfLicense: state?.noOfLicense - 1,
        couponCode: null,
        discountPrice: null,
        isCoupon: false,
        couponAttempt: 1,
      });
    }
  };

  const handleApplyCouponCode = () => {
    if (state?.couponCode) {
      let couponCodeList =
        subcriptionData?.viewData?.couponCode?.length > 0
          ? [...subcriptionData?.viewData?.couponCode]
          : [];

      if (couponCodeList?.length > 0) {
        let match = couponCodeList.some(
          (item) => item.name === state?.couponCode
        );

        if (match) {
          if (state?.couponAttempt === 1) {
            let getCouponCode = couponCodeList?.find(
              (val) => val?.name === state?.couponCode
            );

            // let finalPrice =
            //   state?.price * state?.noOfLicense -
            //   (state?.noOfLicense * state?.price * getCouponCode?.price) / 100;

            setState({
              ...state,
              isCoupon: false,
              couponAttempt: 0,
              discountPrice:
                (state?.noOfLicense * state?.price * getCouponCode?.price) /
                100,
            });
          } else {
            message.error("You already added coupon");
          }
        } else {
          message.error("Invalid coupon code");
        }
      } else {
        message.error("No coupon code available");
      }
    } else {
      message.error("Please enter coupon code");
    }
  };

  const handleTotal = () => {
    message.warning("Please first do login or sign up");
  };

  const getFinanlAmount = (state) => {
    let amt = state?.discountPrice
      ? state?.price * state?.noOfLicense -
        state?.discountPrice +
        (state?.noOfLicense * state?.price * state?.setupCost) / 100
      : state?.price * state?.noOfLicense +
        (state?.noOfLicense * state?.price * state?.setupCost) / 100;

    return amt || 0;
  };

  return (
    <>
      <div>
        <Row>
          <Col span={8}>
            <div
              style={{
                color: "blue",
                cursor: "pointer",
                marginTop: "5px",
                marginLeft: "5px",
              }}
              onClick={() => navigate(PATH_AUTH.root)}
            >
              <ArrowLeftOutlined /> <span>Back to login</span>
            </div>
          </Col>
          <Col span={8}>
            <div style={{ textAlign: "center" }}>
              <h1 style={{ fontWeight: 500 }}>Pricing Structure</h1>
            </div>
          </Col>
          <Col span={8}>
            <div style={{ textAlign: "end" }}>
              <img
                src={PricingStructurePng}
                alt="pricingStructure"
                style={{ width: "250px", height: "200px" }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                fontSize: "16px",
              }}
            >
              <p style={{ fontStyle: "italic" }}>
                "Price is what you pay. Value is what you get."
              </p>
              <p>- Warren Buffet.</p>
            </div>
          </Col>
          <Col span={8}>
            <div style={{ textAlign: "center" }}>
              <p>
                One year per user license:{" "}
                <span style={{ fontWeight: 700, fontSize: "16px" }}>
                  {subcriptionData?.viewData?.perUserPrice || 0} INR
                </span>
                <span style={{ fontWeight: 500, fontSize: "16px" }}>
                  {" "}
                  (Including GST {state.GST}%)
                </span>
              </p>
              <div
                style={{
                  background: "#2e82d1",
                  color: "#fff",
                  borderRadius: "10px",
                  padding: "10px",
                  margin: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "1px",
                  }}
                >
                  <span>Add Nos. of users license you require</span>
                  <span>{RightHandIcon()}</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    width: "30%",
                  }}
                >
                  <span
                    style={{
                      fontSize: "24px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      cursor: "pointer",
                    }}
                    onClick={handlePlusValue}
                  >
                    <Tooltip title="Increase Licenses">
                      <PlusCircleTwoTone />
                    </Tooltip>
                  </span>

                  <span>
                    <Input
                      value={state.noOfLicense}
                      style={{
                        width: "50px",
                        border: "1px solid #fff",
                      }}
                      className="hide-arrows"
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      readOnly={true}
                    />
                  </span>

                  <span
                    style={{
                      fontSize: "24px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      cursor: "pointer",
                    }}
                    onClick={handleMinusValue}
                  >
                    <Tooltip title="Decrease Licenses">
                      <MinusCircleTwoTone />
                    </Tooltip>
                  </span>
                </div>

                {/* <span
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          marginLeft: "5px",
                        }}
                      >
                        X {subcriptionData?.viewData?.perUserPrice || 0} INR
                      </span> */}
              </div>

              <div>
                <span style={{ fontSize: "28px" }}>
                  <PlusOutlined />
                </span>
              </div>

              <div
                style={{
                  background: "#2e82d1",
                  color: "#fff",
                  borderRadius: "10px",
                  margin: "20px",
                }}
              >
                <Row>
                  <Col span={24}>
                    <div>
                      <p>
                        One time setup cost: <span>{state.setupCost} %</span> of
                        Total Value (including GST)
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>

              <div>
                <span style={{ fontSize: "28px" }}>
                  <PauseOutlined rotate={90} />
                </span>
              </div>

              <div>
                {state?.isCoupon ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Input
                        style={{ width: "250px" }}
                        placeholder="Coupon code"
                        value={state?.couponCode}
                        onChange={(event) =>
                          setState({
                            ...state,
                            couponCode: event?.target?.value?.toUpperCase(),
                          })
                        }
                      />
                      <div>
                        <span
                          onClick={handleApplyCouponCode}
                          style={{
                            color: "blue",
                            cursor: "pointer",
                            marginRight: "20px",
                          }}
                        >
                          Apply
                        </span>
                        <span
                          onClick={() =>
                            setState({
                              ...state,
                              isCoupon: false,
                              couponCode: null,
                              couponAttempt: 1,
                              discountPrice: null,
                            })
                          }
                          style={{ color: "red", cursor: "pointer" }}
                        >
                          Cancel
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {state?.couponCode && !state.isCoupon ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <span
                          style={{
                            color: "#2e82d1",
                            fontSize: "16px",
                          }}
                        >
                          Coupon Applied: {state?.couponCode} (
                          {state?.discountPrice} INR)
                        </span>
                        <span
                          onClick={() =>
                            setState({
                              ...state,
                              isCoupon: false,
                              couponCode: null,
                              couponAttempt: 1,
                              discountPrice: null,
                              price: subcriptionData?.viewData?.perUserPrice,
                            })
                          }
                          style={{ color: "red", cursor: "pointer" }}
                        >
                          Remove
                        </span>
                      </div>
                    ) : (
                      <span
                        style={{
                          color: "#2e82d1",
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() => setState({ ...state, isCoupon: true })}
                      >
                        Have you a coupon code?
                      </span>
                    )}
                  </>
                )}
              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    color: `${state?.isCoupon ? "#EBEBE4" : "#F1C946"}`,
                    background: `${state?.isCoupon ? "#c3c3c3" : "#5E5E5E"}`,
                    borderRadius: "10px",
                    margin: "20px",
                    padding: "0px 20px",
                    width: "fit-content",
                    cursor: `${!state?.isCoupon ? "pointer" : "not-allowed"}`,
                  }}
                  onClick={() => (!state?.isCoupon ? handleTotal(state) : {})}
                >
                  <Row>
                    <Col span={24}>
                      <div>
                        <p style={{ fontSize: "16px", fontWeight: 600 }}>
                          Total ={" "}
                          <span style={{ fontWeight: 700 }}>
                            {getFinanlAmount(state)}
                          </span>
                          <span> INR</span>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <div
          style={{
            fontSize: "18px",
            color: "#37b4de",
            fontWeight: 600,
            fontStyle: "italic",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            <span style={{ color: "green", fontSize: "20px" }}>
              <CheckCircleFilled />
            </span>
            <span>Renewal charges are 80% of total users cost.</span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginTop: "20px",
              justifyContent: "center",
            }}
          >
            <span style={{ color: "green", fontSize: "20px" }}>
              <CheckCircleFilled />
            </span>
            <span>Set up cost is for one time charge only.</span>
          </div>
        </div>

        <Divider />

        <div>
          <div
            style={{
              fontSize: "18px",
              color: "#37b4de",
              fontWeight: 500,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <span>Understand the licensing roles</span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              fontSize: "14px",
              marginBottom: "30px",
            }}
          >
            <p
              style={{
                fontWeight: 600,
                fontSize: "16px",
                color: "#1f1f1f",
              }}
            >
              There will be three roles for users:
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <span>1. Super Admin (All rights)</span>
              <span>2. Admin (Viewing and other rights)</span>
              <span>3. Admin (Data making and adding rights)</span>
            </div>

            <p style={{ textAlign: "center" }}>
              Once you select nos of users, you will be given one unique id
              password of Super Admin, in which you will <br />
              select roles and rights of other users if multiple users are
              there.
            </p>

            <p style={{ textAlign: "center" }}>
              Any nos. of users you select, you can use those licenses as Super
              Admin, Admin and Users.
            </p>

            <p style={{ textAlign: "center" }}>
              Eg. if you have selected 5 users, you can use those 5 licenses as,
              1 Super Admin, 2 Admins, and 2 Users or <br />1 Super Admin, 1
              Admin, and 3 Users.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingStructure;

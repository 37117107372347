/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Input,
  Modal,
  Table,
  Tooltip,
  message,
  Upload,
} from "antd";
import {
  EditOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  DeleteOutlined,
  CloudDownloadOutlined,
  InboxOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "./Pagination";
import { useNavigate } from "react-router";
import { PATH_DASHBOARD } from "../../routes/path";
import { helperMethods } from "../../utils/DebounceSearch/helperMethod";
import {
  deleteProductApi,
  downloadExcelForProductSampleApi,
  importProductsApi,
} from "../../redux/apis/dashboardApi";
import { productActions, selectProduct } from "../../redux/slices/productSlice";
import { selectLoggedUser } from "../../redux/slices/authSlice";
const { Dragger } = Upload;

const ProductList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const productData = useSelector(selectProduct);
  const loggedUser = useSelector(selectLoggedUser);

  const [products, setProducts] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [importedFile, setImportedFile] = useState(null);

  useEffect(() => {
    dispatch(
      productActions.productList({
        limit: 10,
        page: 1,
        funnelId: loggedUser?.activeFunnel?._id ?? null,
      })
    );
  }, []);

  useEffect(() => {
    if (productData) {
      setProducts(productData?.list);
    }
  }, [productData]);

  const handleDelete = async (id) => {
    try {
      const deleteRes = await deleteProductApi(id);
      if (deleteRes?.data?.success) {
        message.success(deleteRes?.data?.message);
        dispatch(
          productActions.productList({
            limit: 10,
            page: 1,
            funnelId: loggedUser?.activeFunnel?._id ?? null,
          })
        );
      } else {
        message.error(deleteRes?.data?.message);
      }
    } catch (error) {
      console.log("error", error);
      message.error(error?.response.data?.message);
    }
  };

  const columns = [
    {
      title: "Product No",
      dataIndex: "productNo",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Category",
      dataIndex: "category",
    },
    {
      title: "Product Details",
      dataIndex: "productDetails",
    },
    {
      title: "Original Price",
      dataIndex: "originalPrice",
      render: (_, elm) => (
        <span>&#8377; {Number(elm?.originalPrice).toLocaleString()}</span>
      ),
    },
    {
      title: "Last Price",
      dataIndex: "lastPrice",
      render: (_, elm) => (
        <>
          {elm?.lastPrice ? (
            <span>&#8377; {Number(elm?.lastPrice).toLocaleString()}</span>
          ) : (
            <span>&#8377; {Number(elm?.originalPrice).toLocaleString()}</span>
          )}
        </>
      ),
    },
    {
      title: "New Price",
      dataIndex: "price",
      render: (_, elm) => (
        <span>&#8377; {Number(elm?.price).toLocaleString()}</span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      align: "center",
      render: (_, elm) => (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            gap: 10,
          }}
        >
          <Tooltip title="View/Edit">
            <Button
              className="mr-2"
              icon={<EditOutlined style={{ fontSize: "16px" }} />}
              type="primary"
              size="middle"
              onClick={() => navigate(PATH_DASHBOARD.editProduct(elm._id))}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                handleDelete(elm._id);
              }}
              size="middle"
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const searchInData = (e) => {
    const searchInput = e.target.value;

    dispatch(
      productActions.productSearch({
        searchText: searchInput,
      })
    );
  };

  const debounceOnSearch = React.useCallback(
    helperMethods.debounce(searchInData, 400),
    []
  );

  const handleExcel = async () => {
    try {
      const res = await downloadExcelForProductSampleApi();

      if (res.data) {
        const fileName = `Products-sample.xlsx`;
        const url = window.URL.createObjectURL(new Blob([res.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);

        document.body.appendChild(link);
        link.click();

        message.success("Product Sample Downloaded Successfully");
      } else {
        message.error("Something went Wrong");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleImportProducts = () => {
    setOpenModel(true);
  };

  const handleCloseModel = () => {
    setOpenModel(false);
    setImportedFile(null);
  };

  const props = {
    name: "file",
    multiple: true,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    customRequest({ onSuccess }) {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (status === "done") {
        setImportedFile(info.file?.originFileObj);
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files");
    },
  };

  const handleBeforeUpload = async (file) => {
    const allowedExtensions = [".xlsx", ".xls"];
    const fileExtension = file.name
      .substring(file.name.lastIndexOf("."))
      .toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      message.error("Invalid file format. Please upload an Excel file.");
      return false;
    }

    return true;
  };

  const handleUploadProductsFile = async () => {
    try {
      window.Buffer = window.Buffer || require("buffer").Buffer;
      let formDataObject = new FormData();
      formDataObject.append("products", importedFile);

      const res = await importProductsApi(formDataObject);

      if (res.data.success) {
        message.success(res.data.message);
        setImportedFile(null);

        setTimeout(() => {
          setOpenModel(false);
          dispatch(
            productActions.productList({
              limit: 10,
              page: 1,
              funnelId: loggedUser?.activeFunnel?._id ?? null,
            })
          );
        }, 1000);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    }
  };

  let updatedColumns = [...columns];

  if (
    loggedUser?.addEditDeleteRights?.length > 0 &&
    loggedUser?.addEditDeleteRights
      ?.filter((v) => v.value === "products")
      .some((val) => val?.isAddEditDelete === true)
  ) {
    updatedColumns = [...updatedColumns];
  } else {
    delete updatedColumns[updatedColumns.length - 1];
  }

  return (
    <>
      <h2>Product List</h2>
      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Input
            size="large"
            placeholder="Search..."
            prefix={<SearchOutlined />}
            onChange={(e) => {
              debounceOnSearch(e);
            }}
            allowClear
            onClear={(e) => debounceOnSearch()}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            type="default"
            icon={<CloudDownloadOutlined />}
            size="large"
            style={{ marginRight: "10px" }}
            onClick={handleImportProducts}
          >
            Import Products
          </Button>
          {loggedUser?.addEditDeleteRights?.length > 0 &&
            loggedUser?.addEditDeleteRights
              ?.filter((v) => v.value === "products")
              .some((val) => val?.isAddEditDelete === true) && (
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                size="large"
                onClick={() => navigate(PATH_DASHBOARD.addProduct)}
              >
                New Product
              </Button>
            )}
        </div>
      </div>
      <div className="table-responsive">
        <Card>
          <>
            <Table
              columns={updatedColumns}
              dataSource={products}
              loading={productData?.isLoading}
              rowKey="id"
              pagination={false}
              scroll={{
                x: 1000,
              }}
            />
            <div style={{ marginTop: "20px" }}>
              <CustomPagination />
            </div>
          </>
        </Card>
      </div>
      <div style={{ marginTop: "20px", float: "right" }}>
        <span style={{ marginRight: "20px", color: "red" }}>
          * Please Do Not Change Header
        </span>
        <Button onClick={handleExcel} type="dashed">
          Download Excel Sample
        </Button>
      </div>

      <Modal
        title="Import Products"
        open={openModel}
        onCancel={handleCloseModel}
        onOk={handleUploadProductsFile}
        okText={"Upload"}
        footer={[
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button onClick={handleExcel} type="dashed">
              Download Excel Sample
            </Button>
            <div>
              <Button onClick={handleCloseModel} type="default">
                Cancel
              </Button>
              <Button onClick={handleUploadProductsFile} type="primary">
                Upload
              </Button>
            </div>
          </div>,
        ]}
      >
        <div style={{ marginBottom: "20px" }}>
          <Dragger
            accept=".xlsx, .xls"
            maxCount={1}
            multiple={false}
            beforeUpload={handleBeforeUpload}
            {...props}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support only excel file format. Please Check Sample
            </p>
          </Dragger>
        </div>
      </Modal>
    </>
  );
};

export default ProductList;

import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    fontStyle: "bold",
  },
  description: {
    width: "50%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 8,
    display: "flex",
    flexWrap: "wrap",
  },
  qty: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  rate: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  amount: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
  },
});

const InvoiceTableRow = ({ state, subcriptionData }) => {
  const rowsData = [state?.invoiceData];

  const rows = rowsData?.map((item, i) => {
    let noOfLicense = item?.isMainLicense
      ? item?.mainLicense
      : item?.subLicense;

    let isDemo = item?.superUserId?.accountType === "demo" ? true : false;

    let amount = (
      noOfLicense * subcriptionData?.viewData?.perUserPrice
    )?.toFixed(2);

    return (
      <View style={styles.row} key={i}>
        <Text style={styles.description}>
          {item?.licenseIds?.length > 0
            ? item?.licenseIds?.map((lic) => lic?.licId)?.join(", ")
            : ""}
        </Text>
        <Text style={styles.qty}>{noOfLicense || 0}</Text>
        <Text style={styles.rate}>
          {isDemo ? "0" : subcriptionData?.viewData?.perUserPrice?.toFixed(2)}
        </Text>
        <Text style={styles.amount}>{amount || "0"}</Text>
      </View>
    );
  });

  return <>{rows}</>;
};

export default InvoiceTableRow;

/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Button, Card, Input, Table, Tooltip, message } from "antd";
import {
  EditOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  customerActions,
  selectCustomer,
} from "../../redux/slices/customerSlice";
import CustomPagination from "./Pagination";
import { useNavigate } from "react-router";
import { PATH_DASHBOARD } from "../../routes/path";
import { helperMethods } from "../../utils/DebounceSearch/helperMethod";
import { deleteCustomerApi } from "../../redux/apis/dashboardApi";
import { selectLoggedUser } from "../../redux/slices/authSlice";

const CustomerList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const customerData = useSelector(selectCustomer);
  const loggedUser = useSelector(selectLoggedUser);

  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    dispatch(customerActions.customerList({ limit: 10, page: 1 }));
  }, []);

  useEffect(() => {
    if (customerData) {
      setCustomers(customerData?.list);
    }
  }, [customerData]);

  const handleDelete = async (id) => {
    try {
      const deleteRes = await deleteCustomerApi(id);
      if (deleteRes?.data?.success) {
        message.success(deleteRes?.data?.message);
        dispatch(customerActions.customerList({ limit: 10, page: 1 }));
      } else {
        message.error(deleteRes?.data?.message);
      }
    } catch (error) {
      console.log("error", error);
      message.error(error?.response.data?.message);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "GST No.",
      dataIndex: "gstNo",
    },
    {
      title: "Industry",
      dataIndex: "industry",
    },
    {
      title: "Registered Office Address",
      dataIndex: "officeAddress",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      align: "center",
      render: (_, elm) => (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            gap: 10,
          }}
        >
          <Tooltip title="View/Edit">
            <Button
              className="mr-2"
              icon={<EditOutlined style={{ fontSize: "16px" }} />}
              type="primary"
              size="middle"
              onClick={() => navigate(PATH_DASHBOARD.editCustomer(elm._id))}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                handleDelete(elm._id);
              }}
              size="middle"
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const searchInData = (e) => {
    const searchInput = e.target.value;

    dispatch(
      customerActions.customerSearch({
        searchText: searchInput,
      })
    );
  };

  const debounceOnSearch = React.useCallback(
    helperMethods.debounce(searchInData, 400),
    []
  );

  let updatedColumns = [...columns];

  if (
    loggedUser?.addEditDeleteRights?.length > 0 &&
    loggedUser?.addEditDeleteRights
      ?.filter((v) => v.value === "customerFiles")
      .some((val) => val?.isAddEditDelete === true)
  ) {
    updatedColumns = [...updatedColumns];
  } else {
    delete updatedColumns[updatedColumns.length - 1];
  }

  return (
    <>
      <h2>Customer List</h2>
      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Input
            size="large"
            placeholder="Search..."
            prefix={<SearchOutlined />}
            onChange={(e) => {
              debounceOnSearch(e);
            }}
            allowClear
            onClear={(e) => debounceOnSearch()}
          />
        </div>
        {loggedUser?.addEditDeleteRights?.length > 0 &&
          loggedUser?.addEditDeleteRights
            ?.filter((v) => v.value === "customerFiles")
            .some((val) => val?.isAddEditDelete === true) && (
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              size="large"
              onClick={() => navigate(PATH_DASHBOARD.addCustomer)}
            >
              New Customer
            </Button>
          )}
      </div>
      <div className="table-responsive">
        <Card>
          <>
            <Table
              columns={updatedColumns}
              dataSource={customers}
              loading={customerData?.isLoading}
              rowKey="id"
              pagination={false}
              scroll={{
                x: 1000,
              }}
            />
            <div style={{ marginTop: "20px" }}>
              <CustomPagination />
            </div>
          </>
        </Card>
      </div>
    </>
  );
};

export default CustomerList;

import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../redux/slices/authSlice";
import { selectUnitQty } from "../../utils/general-constant";
import { PlusIcon } from "../../assets/svgs/icons";

const TotalPotentialValueForm = ({
  open,
  setOpen,
  formDefine,
  handleSubmit,
  submitLoading,
  rowData,
  setRowData,
}) => {
  const loggedUser = useSelector(selectLoggedUser);
  const presetsData = loggedUser?.presets;

  const [totalPotentialData, setTotalPotentialData] = useState(null);

  const rules = {
    // competitionSelection: [
    //   {
    //     required: true,
    //     message: "Please Enter Competition Selection",
    //   },
    // ],
    competitionVolume: [
      {
        required: true,
        message: "Please Enter Competition Volume",
      },
    ],
    // competitionPrice: [
    //   {
    //     required: true,
    //     message: "Please Enter Competition Price",
    //   },
    // ],
    // orderConversionValue: [
    //   {
    //     required: true,
    //     message: "Please Enter Initial Conversion Value",
    //   },
    // ],
    // currentCoversionValue: [
    //   {
    //     required: true,
    //     message: "Please Enter Current Conversion Value",
    //   },
    // ],
    // product: [
    //   {
    //     required: true,
    //     message: "Please Enter Product",
    //   },
    // ],
    existingSaleVolume: [
      {
        required: true,
        message: "Please Enter existing sale volume",
      },
    ],
    // productPrice: [
    //   {
    //     required: true,
    //     message: "Please Enter product price",
    //   },
    // ],
    // existingSaleVolue: [
    //   {
    //     required: true,
    //     message: "Please Enter existing initial sale value",
    //   },
    // ],
    // existingSaleCurrentVolue: [
    //   {
    //     required: true,
    //     message: "Please Enter Saint gobain existing current sale value",
    //   },
    // ],
    // total: [
    //   {
    //     required: true,
    //     message: "Please Enter Total",
    //   },
    // ],
  };

  useEffect(() => {
    if (rowData) {
      const productSpecs =
        rowData?.productAgaintCompetition?.productSpecs || "";
      const productName = rowData?.productAgaintCompetition?.name || "";

      // Check the length of productSpecs and format the string accordingly
      const displayText =
        productSpecs.length > 25
          ? `${productName} (${productSpecs.slice(0, 25)} ...)`
          : `${productName} ${productSpecs}`;

      formDefine?.setFieldsValue({
        competitionSelection: rowData?.competitionBrand?.name,
        competitionPrice: rowData?.competitionBrand?.price,
        product: rowData?.productAgaintCompetition?.name,
        productPrice: rowData?.productAgaintCompetition?.price,
        combineProductData: displayText,
      });
    }
    // eslint-disable-next-line
  }, [rowData]);

  const handleCancel = () => {
    setOpen(false);
    formDefine?.resetFields();
    setTotalPotentialData(null);
    setRowData({
      ...rowData,
      row: null,
    });
  };

  const handleChangeValue = (values) => {
    if (values) {
      const updatedData = { ...totalPotentialData, ...values };

      // Calculate new values
      let val1 = 0;
      let val2 = 0;

      if (updatedData?.competitionVolume && rowData?.competitionBrand?.price) {
        val1 =
          Number(updatedData?.competitionVolume) *
          Number(rowData?.competitionBrand?.price);
      }

      if (
        updatedData?.existingSaleVolume &&
        rowData?.productAgaintCompetition?.price
      ) {
        val2 =
          Number(updatedData?.existingSaleVolume) *
          Number(rowData?.productAgaintCompetition?.price);
      }

      const updatedValues = {
        ...updatedData,
        orderConversionValue: val1,
        existingSaleVolue: val2,
        total: val1 + val2,
      };

      // Update state and form field values
      setTotalPotentialData(updatedValues);
      formDefine?.setFieldsValue(updatedValues);
    }
  };

  return (
    <>
      <Modal
        title="Add/Edit Potential Values"
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={970}
      >
        <div className="d-flex flex-column justify-content-center h-100">
          <div className="mt-4">
            <Form
              name={formDefine}
              layout="vertical"
              form={formDefine}
              onFinish={handleSubmit}
              onValuesChange={handleChangeValue}
            >
              <div style={{ display: "flex", gap: "40px" }}>
                <div>
                  <p>Competition Product (A)</p>
                  <Row gutter={12}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        name="competitionSelection"
                        label="Competition Selection"
                        rules={rules.competitionSelection}
                      >
                        <Input
                          disabled={true}
                          placeholder="Competition Selection"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        name="competitionPrice"
                        label="Competition Price"
                        rules={rules.competitionPrice}
                      >
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Competition Price"
                          className="hide-arrows"
                          disabled={true}
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Form.Item
                        name="competitionVolume"
                        label={`Volume (Per ${
                          presetsData?.potentialValue ?? ""
                        } QTY) / (${presetsData?.consumptionValue ?? ""})`}
                        rules={rules.competitionVolume}
                      >
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Competition Volume"
                          className="hide-arrows"
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                    {loggedUser?.presets?.isSetDefaultUnit ? (
                      <>
                        <Col xs={16} sm={16} md={12}>
                          <div style={{ marginTop: "55px" }}>
                            Default Unit: {loggedUser?.presets?.unitValue}
                          </div>
                        </Col>
                      </>
                    ) : (
                      <Col xs={16} sm={16} md={12}>
                        <Form.Item
                          label="Select Unit For QTY/Volume"
                          name="compititionUnitValue"
                          rules={[
                            {
                              required: true,
                              message: "Please Select Unit For QTY/Volume",
                            },
                          ]}
                        >
                          <Select
                            style={{
                              width: "100%",
                            }}
                            options={selectUnitQty}
                            placeholder="Select Unit"
                            allowClear
                            showSearch
                          />
                        </Form.Item>
                      </Col>
                    )}

                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        name="orderConversionValue"
                        label="Competition Potential Initial Value"
                        rules={rules.orderConversionValue}
                      >
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Value"
                          disabled={true}
                          className="hide-arrows"
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        name="currentCoversionValue"
                        label="Competition Potential Current Value"
                        // rules={rules.currentCoversionValue}
                      >
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Value"
                          disabled={true}
                          className="hide-arrows"
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>

                <div
                  style={{
                    width: "5px",
                    minWidth: "1px",
                    margin: "0px 10px",
                    background: "#f0efed",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      position: "relative",
                      right: "23px",
                      fontSize: "30px",
                      color: "gray",
                    }}
                  >
                    {PlusIcon()}
                  </span>
                </div>

                <div>
                  <p>
                    {loggedUser?.activeFunnel?.funnelName || ""} Product (B)
                  </p>
                  <Row gutter={12}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        name="combineProductData"
                        label={`${
                          loggedUser?.activeFunnel?.funnelName || ""
                        } Product`}
                        rules={rules.product}
                      >
                        <Input disabled={true} placeholder="Product" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        name="productPrice"
                        label={`${
                          loggedUser?.activeFunnel?.funnelName || ""
                        } product price to customer`}
                        rules={rules.productPrice}
                      >
                        <Input
                          style={{ width: "100%" }}
                          placeholder={`${
                            loggedUser?.activeFunnel?.funnelName || ""
                          } product price to customer`}
                          className="hide-arrows"
                          disabled={true}
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Form.Item
                        name="existingSaleVolume"
                        label={`Volume (per ${
                          presetsData?.potentialValue ?? ""
                        } QTY) / (${presetsData?.consumptionValue ?? ""})`}
                        rules={rules.existingSaleVolume}
                      >
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Enter Qty"
                          className="hide-arrows"
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                    {loggedUser?.presets?.isSetDefaultUnit ? (
                      <>
                        <Col xs={16} sm={16} md={12}>
                          <div style={{ marginTop: "55px" }}>
                            Default Unit: {loggedUser?.presets?.unitValue}
                          </div>
                        </Col>
                      </>
                    ) : (
                      <Col xs={16} sm={16} md={12}>
                        <Form.Item
                          label="Select Unit For QTY/Volume"
                          name="existingSaleUnitValue"
                          rules={[
                            {
                              required: true,
                              message: "Please Select Unit For QTY/Volume",
                            },
                          ]}
                        >
                          <Select
                            style={{
                              width: "100%",
                            }}
                            options={selectUnitQty}
                            placeholder="Select Unit"
                            allowClear
                            showSearch
                          />
                        </Form.Item>
                      </Col>
                    )}

                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        name="existingSaleVolue"
                        label={`${
                          loggedUser?.activeFunnel?.funnelName || ""
                        } existing sale value (b)`}
                        rules={rules.existingSaleVolue}
                      >
                        <Input
                          style={{ width: "100%" }}
                          placeholder={`${
                            loggedUser?.activeFunnel?.funnelName || ""
                          } existing initial sale value`}
                          disabled={true}
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          className="hide-arrows"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        name="existingSaleCurrentVolue"
                        label={`${
                          loggedUser?.activeFunnel?.funnelName || ""
                        } existing current sale value (b)`}
                        // rules={rules.existingSaleCurrentVolue}
                      >
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Value"
                          disabled={true}
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          className="hide-arrows"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
              <Row gutter={12}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="total"
                    label={`Total (a + b) (per ${
                      presetsData?.potentialValue ?? ""
                    } QTY) / (${presetsData?.consumptionValue ?? ""})`}
                    rules={rules.total}
                  >
                    <Input
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      style={{ width: "100%" }}
                      placeholder="Total"
                      disabled={true}
                      className="hide-arrows"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <Button onClick={handleCancel} htmlType="reset" type="default">
                  Cancel
                </Button>
                <Button
                  loading={submitLoading}
                  htmlType="submit"
                  type="primary"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TotalPotentialValueForm;

import { Button, Col, Form, Input, Modal, Row } from "antd";
import React from "react";

const AddCompetitionForm = ({
  open,
  setOpen,
  formDefine,
  handleSubmit,
  submitLoading,
  rowData,
}) => {
  const rules = {
    name: [
      {
        required: true,
        message: "Please Enter Name",
      },
    ],
    productSpecs: [
      {
        required: true,
        message: "Please Enter Product Specifications",
      },
    ],
    price: [
      {
        required: true,
        message: "Please Enter Price",
      },
    ],
  };

  const handleCancel = () => {
    setOpen(false);
    formDefine?.resetFields();
  };

  return (
    <>
      <Modal
        title="Add/Edit Competition"
        open={open}
        onCancel={handleCancel}
        footer={null}
      >
        <div>
          <Row justify="center">
            <Col xs={24} sm={24} md={20} lg={12} xl={18}>
              <div className="mt-4">
                <Form
                  name={formDefine}
                  layout="vertical"
                  form={formDefine}
                  onFinish={handleSubmit}
                >
                  <Row gutter={12}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item name="name" label="Name" rules={rules.name}>
                        <Input placeholder="Competition Name" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        name="productSpecs"
                        label="Product Specifications"
                        rules={rules.productSpecs}
                      >
                        <Input.TextArea placeholder="Product Specifications" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item name="price" label="Price" rules={rules.price}>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Competition Price"
                          className="hide-arrows"
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      onClick={handleCancel}
                      htmlType="reset"
                      type="default"
                    >
                      Cancel
                    </Button>
                    <Button
                      loading={submitLoading}
                      htmlType="submit"
                      type="primary"
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default AddCompetitionForm;

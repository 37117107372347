import {
  Avatar,
  Popover,
  Row,
  Space,
  Col,
  Typography,
  Divider,
  message,
  Select,
  Button,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EyeOutlined, LogoutOutlined, ReloadOutlined } from "@ant-design/icons";
import userLogo from "../../assets/images/user.png";
import { authActions, selectLoggedUser } from "../../redux/slices/authSlice";
import logo from "../../assets/images/funneline.jpg";
import { useNavigate } from "react-router";
import { PATH_DASHBOARD } from "../../routes/path";
import { funnelActions, selectFunnel } from "../../redux/slices/funnelSlice";
import uniqueArrayList from "../../utils/UniqueArrayList";
import { editFunnelApi } from "../../redux/apis/dashboardApi";
import { persistor } from "../../redux/store";

const { Option } = Select;

const Header = () => {
  const { Text } = Typography;

  const [openProfile, setOpenProfile] = useState(false);
  const [funnels, setFunnels] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [defaultSelectedFunnel, setDefaultSelectedFunnel] = useState(null);

  const loggedUser = useSelector(selectLoggedUser);
  const funnelData = useSelector(selectFunnel);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOpenProfile = (newOpen) => {
    setOpenProfile(newOpen);
  };

  const handleLogout = async () => {
    try {
      await persistor.flush();
      await persistor.purge();
      localStorage.clear();

      dispatch(authActions.logout());

      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    } catch (error) {
      message.error(error);
    }
  };

  useEffect(() => {
    dispatch(funnelActions.funnelList({ limit: 1000, page: currentPage }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (funnelData || defaultSelectedFunnel) {
      let newArr = [...funnelData?.list, ...funnels];
      let finalArr = uniqueArrayList(newArr, "_id");
      if (finalArr?.length) {
        setFunnels(finalArr);
        if (finalArr?.length) {
          setDefaultSelectedFunnel(loggedUser?.activeFunnel?.funnelName);
        } else {
          setDefaultSelectedFunnel(null);
        }
      } else {
        setDefaultSelectedFunnel(null);
      }
    }
    // eslint-disable-next-line
  }, [funnelData]);

  useEffect(() => {
    if (loggedUser && Object.keys(loggedUser?.activeFunnel).length > 0) {
      setDefaultSelectedFunnel(loggedUser?.activeFunnel?.funnelName);
    } else {
      setDefaultSelectedFunnel(null);
    }
  }, [loggedUser]);

  const handleRefreshFunnel = () => {
    window.location.reload();
  };

  const handleChangeFunnel = async (value) => {
    try {
      if (value) {
        const selectedFunnel = funnels.find(
          (funnel) => funnel.funnelName === value
        );

        if (selectedFunnel) {
          const activeFunnel = await editFunnelApi(selectedFunnel?._id, {
            isActive: true,
          });
          if (activeFunnel?.data?.success) {
            setDefaultSelectedFunnel(selectedFunnel.funnelName);

            setTimeout(() => {
              dispatch(
                funnelActions.funnelList({ limit: 10, page: currentPage })
              );
              dispatch(authActions.isLogin());
              message.success(activeFunnel?.data?.message);
            }, 800);
          } else {
            setDefaultSelectedFunnel(null);
            message.error(activeFunnel?.data?.message);
          }
        }
      }
    } catch (error) {
      setDefaultSelectedFunnel(null);
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Row
        align={"middle"}
        justify={"space-between"}
        style={{ marginRight: "20px" }}
      >
        <Row align={"middle"} style={{ height: "54px" }}>
          <div
            onClick={() => navigate(PATH_DASHBOARD.root)}
            style={{ cursor: "pointer" }}
          >
            <img
              alt="FunneLine"
              src={logo}
              style={{ height: 64, width: "300px" }}
            />
          </div>
        </Row>
        <Row align={"middle"} justify={"space-between"} style={{ gap: "20px" }}>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            {!loggedUser?.isTrialAttemptExpired &&
              loggedUser?.viewOnlyRights?.length > 0 &&
              loggedUser?.viewOnlyRights
                ?.filter((v) => v.value === "funnel")
                .some((val) => val?.isView === true) && (
                <div>
                  <Tooltip title="View Funnel">
                    <Button
                      type="primary"
                      icon={<EyeOutlined />}
                      onClick={() => navigate(PATH_DASHBOARD.funnel)}
                    />
                  </Tooltip>
                </div>
              )}

            <div>
              <Tooltip title="Refresh Funnel">
                <Button
                  type="default"
                  icon={<ReloadOutlined />}
                  onClick={() => handleRefreshFunnel()}
                />
              </Tooltip>
            </div>
            <Select
              style={{ width: "250px", textTransform: "capitalize" }}
              placeholder="Select Funnel"
              onChange={handleChangeFunnel}
              value={defaultSelectedFunnel}
              onPopupScroll={(e) => {
                const target = e.target;
                if (
                  target.scrollTop + target.clientHeight ===
                  target.scrollHeight
                ) {
                  if (funnelData?.isNext) {
                    dispatch(
                      funnelActions.funnelList({
                        limit: 10,
                        page: currentPage + 1,
                      })
                    );
                    setCurrentPage(currentPage + 1);
                  }
                }
              }}
            >
              {funnels?.map((v) => (
                <Option value={v?.funnelName} key={v?._id}>
                  <span style={{ textTransform: "capitalize" }}>
                    {v?.funnelName}
                  </span>
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <Popover
              overlayInnerStyle={{
                padding: 0,
                marginRight: "20px",
                width: "240px",
              }}
              open={openProfile}
              onOpenChange={handleOpenProfile}
              content={
                <>
                  <Row
                    style={{
                      padding: 12,
                      cursor: "auto",
                    }}
                  >
                    <Space size={"middle"}>
                      <Col>
                        <Avatar
                          size={"large"}
                          style={{
                            cursor: "pointer",
                            marginTop: "10px",
                            border: "1px solid #d3d3d3",
                          }}
                          src={loggedUser?.image ?? userLogo}
                        />
                      </Col>
                      <Col>
                        <Row align="middle" style={{ width: "100%" }}>
                          <Text>
                            {loggedUser?.firstName} {loggedUser?.lastName}
                          </Text>
                          <Text
                            style={{
                              marginLeft: "5px",
                              textTransform: "capitalize",
                              fontSize: "12px",
                              fontWeight: 600,
                            }}
                          >
                            (
                            {loggedUser?.role?.roleName === "superUser"
                              ? "Super Admin"
                              : loggedUser?.role?.roleName === "userAdmin"
                              ? "Admin"
                              : loggedUser?.role?.roleName === "subUser"
                              ? "User"
                              : ""}
                            )
                          </Text>
                        </Row>
                        <Text
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          {loggedUser?.email}
                        </Text>
                      </Col>
                    </Space>
                  </Row>
                  <Divider
                    style={{
                      margin: 0,
                    }}
                  />
                  <div
                    style={{
                      padding: "5px",
                    }}
                    onClick={() => {
                      setOpenProfile(false);
                      handleLogout();
                    }}
                  >
                    <div style={{ cursor: "pointer", padding: "12px" }}>
                      <Row
                        align={"middle"}
                        style={{
                          gap: 10,
                        }}
                      >
                        <LogoutOutlined
                          style={{
                            fontSize: "19px",
                          }}
                        />
                        <Text
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          Log out
                        </Text>
                      </Row>
                    </div>
                  </div>
                </>
              }
              trigger="click"
              arrow={false}
              placement="bottomLeft"
            >
              <Avatar
                size={"large"}
                style={{
                  cursor: "pointer",
                  marginTop: "10px",
                  border: "1px solid #d3d3d3",
                }}
                src={loggedUser?.image ?? userLogo}
              />
            </Popover>
          </div>
        </Row>
      </Row>
    </>
  );
};

export default Header;

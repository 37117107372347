import { Modal, Form, Input, Select, Row, Col, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import {
  addPaymentLicenseIdsApi,
  addSubUserAdminApi,
  editSubUserAdminApi,
  mainRolesListApi,
  updateProfile,
} from "../../redux/apis/dashboardApi";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSuperUser,
  superUserActions,
} from "../../redux/slices/superUserSlice";
import { helperMethods } from "../../utils/DebounceSearch/helperMethod";
import uniqueArrayList from "../../utils/UniqueArrayList";
import { roleActions } from "../../redux/slices/roleSlice";
import { authActions } from "../../redux/slices/authSlice";
import {
  selectSubcription,
  subcriptionActions,
} from "../../redux/slices/subcriptionSlice";

const { Option } = Select;

const initialLicenseData = {
  id: null,
  data: null,
  paymentId: null,
};

const AddEditRoleModel = ({ data, setData, loggedUser }) => {
  const [addEditRoleForm] = Form.useForm();

  const [roles, setRoles] = useState([]);
  const [superUsers, setSuperUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [licenseData, setLicenseData] = useState(initialLicenseData);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const dispatch = useDispatch();

  const superUserData = useSelector(selectSuperUser);
  const subcriptionData = useSelector(selectSubcription);

  useEffect(() => {
    if (data?.type === "edit") {
      let reportingTo = [];

      if (data?.data?.reportingTo?.length > 0) {
        reportingTo = data?.data?.reportingTo?.map((v) => v?._id);
      }

      addEditRoleForm.setFieldsValue({
        firstName: data?.data?.firstName,
        lastName: data?.data?.lastName,
        designation: data?.data?.designation,
        email: data?.data?.email,
        mobile: data?.data?.mobile,
        role:
          data?.data?.role?.roleName === "superUser"
            ? "Super Admin"
            : data?.data?.role?.roleName === "subUser"
            ? "User"
            : data?.data?.role?.roleName === "userAdmin"
            ? "Admin"
            : data?.data?.role?.roleName,
        companyId: data?.data?.companyId,
        reportingTo,
      });

      let licenseIds =
        subcriptionData?.licenseIds?.length > 0
          ? [...subcriptionData?.licenseIds]
          : [];

      if (licenseIds.length > 0) {
        let getLId = licenseIds?.find(
          (lic) => lic?.user && lic.user?.email === data?.data?.email
        );

        setLicenseData({
          ...licenseData,
          id: getLId?.licId,
          paymentId: getLId?.paymentId,
        });
      } else {
        licenseIds = [];
      }
    }
    // eslint-disable-next-line
  }, [data?.type, subcriptionData]);

  const rolesListFetch = async () => {
    try {
      const res = await mainRolesListApi();
      if (res?.data?.success) {
        let allRole = res?.data?.data;

        if (allRole?.length > 0) {
          let userAdminRoles = allRole?.filter(
            (v) => v?.roleName === "userAdmin" || v?.roleName === "subUser"
          );
          setRoles(userAdminRoles);
        }
      } else {
        message.error(res?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    if (data?.model) {
      rolesListFetch();
      dispatch(
        superUserActions.superUserList({ limit: 9999, page: currentPage })
      );
      dispatch(subcriptionActions.licenseIds({ userId: loggedUser?._id }));
    }
    // eslint-disable-next-line
  }, [data?.model]);

  useEffect(() => {
    if (superUserData) {
      let superUsersList = superUserData?.list?.filter(
        (v) => v?.role?.roleName === "superUser"
      );

      let newArr = [...superUsers, ...superUsersList];
      let finalArr = uniqueArrayList(newArr, "_id");

      if (finalArr?.length) {
        setSuperUsers(finalArr);
      }
    }
    // eslint-disable-next-line
  }, [superUserData]);

  useEffect(() => {
    if (data?.data && loggedUser) {
      let checkedSuperAdmin = data?.data?._id === loggedUser?._id;

      setIsSuperAdmin(checkedSuperAdmin);
    } else {
      setIsSuperAdmin(false);
    }
  }, [data?.data, loggedUser]);

  const superUserInData = (val) => {
    if (val) {
      dispatch(superUserActions?.superUserSearch({ searchText: val }));
    }
  };

  // eslint-disable-next-line
  const debounceSuperUser = useCallback(
    helperMethods.debounce(superUserInData, 500),
    []
  );

  const handleCancel = () => {
    addEditRoleForm.resetFields();
    setData({ data: null, model: false });
  };

  const fetchLicenseId = () => {
    let licenseIds =
      subcriptionData?.licenseIds?.length > 0
        ? [...subcriptionData?.licenseIds]
        : [];

    if (licenseIds.length > 0) {
      let getLId = licenseIds?.find(
        (lic) =>
          !lic.user ||
          (typeof lic.user === "object" && Object.keys(lic.user).length === 0)
      );

      setLicenseData({
        ...licenseData,
        id: getLId?.licId,
        paymentId: getLId?.paymentId,
      });
    } else {
      licenseIds = [];
    }
  };

  useEffect(() => {
    if (subcriptionData && data?.type !== "edit") {
      fetchLicenseId();
    }
    // eslint-disable-next-line
  }, [subcriptionData]);

  const onFinish = async (values) => {
    try {
      if (values) {
        let res = null;

        if (data?.type === "edit") {
          if (isSuperAdmin) {
            res = await updateProfile(loggedUser?._id, {
              ...values,
              role: data?.data?.role[0]?._id,
            });
          } else {
            res = await editSubUserAdminApi(data?.data?._id, {
              ...values,
              createdBy: loggedUser?._id,
              role: data?.data?.role[0]?._id,
            });
          }
        } else {
          const submitData = {
            ...values,
            createdBy: loggedUser?._id,
            licenseData: licenseData,
          };

          res = await addSubUserAdminApi(submitData);
        }

        if (res?.data?.success) {
          message.success(res?.data?.message);
          setCurrentPage(1);
          addEditRoleForm.resetFields();
          dispatch(authActions.isLogin());
          dispatch(
            roleActions.roleList({
              limit: 10,
              page: 1,
              createdBy: loggedUser?._id,
            })
          );

          if (!isSuperAdmin) {
            setLicenseData({
              ...licenseData,
              data: res?.data?.data,
            });
            addPaymentLicenseIds({
              ...values,
              createdBy: loggedUser?._id,
              role: data?.data?.role[0]?._id,
            });
          } else {
            setData({ data: null, model: false });
          }
        } else {
          message.error(res?.data?.message);
        }
      }
    } catch (error) {
      message.error(error?.response?.data?.message ?? "Something went wrong");
    }
  };

  const addPaymentLicenseIds = async (user) => {
    try {
      if (user) {
        const payload = {
          userId: loggedUser?._id || "",
          paymentId: licenseData?.paymentId || "",
          licId: licenseData?.id || "",
          user,
        };

        const res = await addPaymentLicenseIdsApi(payload);

        if (res?.data?.success) {
          setData({ data: null, model: false });

          setLicenseData(initialLicenseData);
        } else {
          message.error(res?.data?.message ?? "Something went wrong");
        }
      }
    } catch (error) {
      message.error(error?.response?.data?.message ?? "Something went wrong");
    }
  };

  return (
    <>
      <Modal
        title={data?.type === "add" ? "Add Admin/User" : "Edit Admin/User"}
        open={data?.model}
        onCancel={() => handleCancel()}
        okText="Submit"
        onOk={() => addEditRoleForm.submit()}
      >
        <Form
          layout="vertical"
          form={addEditRoleForm}
          onFinish={onFinish}
          initialValues={{ companyId: "" }}
        >
          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[{ required: true, message: "Please enter first name" }]}
              >
                <Input placeholder="First Name" disabled={isSuperAdmin} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[{ required: true, message: "Please enter last name" }]}
              >
                <Input placeholder="Last Name" disabled={isSuperAdmin} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="designation"
                label="Designation"
                rules={[
                  { required: true, message: "Please enter a designation" },
                ]}
              >
                <Input placeholder="Designation" disabled={isSuperAdmin} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Please enter an email" },
                  { type: "email", message: "Invalid email format" },
                ]}
              >
                <Input placeholder="Email" disabled={isSuperAdmin} />
              </Form.Item>
            </Col>

            {!isSuperAdmin && (
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: data?.type === "edit" ? false : true,
                      message: "Please enter password",
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
              </Col>
            )}

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="companyId"
                label="Company ID"
                rules={[
                  { required: true, message: "Please enter a company ID" },
                ]}
              >
                <Input placeholder="Company Id" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="mobile"
                label="Mobile"
                rules={[
                  { required: true, message: "Please enter a mobile number" },
                  {
                    pattern: /^\d{10}$/,
                    message: "Invalid mobile number format",
                  },
                ]}
              >
                <Input placeholder="Mobile" disabled={isSuperAdmin} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="reportingTo"
                label="Reporting To"
                rules={[
                  {
                    required: !isSuperAdmin,
                    message: "Please select reporting user(s)",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="Select Reporting"
                  onChange={(e) => debounceSuperUser(e)}
                  style={{ width: "100%" }}
                  // value={defaultSelectedProduct}
                  allowClear
                  onPopupScroll={(e) => {
                    const target = e.target;
                    if (
                      target.scrollTop + target.clientHeight ===
                      target.scrollHeight
                    ) {
                      if (superUserData?.isNext) {
                        dispatch(
                          superUserActions.superUserList({
                            limit: 10,
                            page: currentPage + 1,
                          })
                        );
                        setCurrentPage(currentPage + 1);
                      }
                    }
                  }}
                  onClear={() => debounceSuperUser("")}
                  showSearch
                  disabled={isSuperAdmin}
                >
                  {superUsers.map((user) => (
                    <Option key={user?._id} value={user?._id}>
                      {user?.firstName} {user?.lastName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="role"
                label="Select Role"
                rules={[
                  {
                    required: !isSuperAdmin,
                    message: "Please select role",
                  },
                ]}
              >
                <Select
                  placeholder="Select Role"
                  disabled={data?.type === "edit" ? true : false}
                >
                  {roles?.map((role) => (
                    <Option key={role?._id} value={role?._id}>
                      {role?.roleName === "subUser"
                        ? "User"
                        : role?.roleName === "userAdmin"
                        ? "Admin"
                        : role?.roleName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <div>
          <span style={{ color: "red" }}>License ID:- </span>
          <span style={{ fontWeight: 600 }}>{licenseData?.id || ""}</span>
        </div>
      </Modal>
    </>
  );
};

export default AddEditRoleModel;

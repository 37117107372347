import React, { useEffect, useState } from "react";
import { helperMethods } from "../../utils/DebounceSearch/helperMethod";
import CustomPagination from "./Pagination";
import {
  Button,
  Tooltip,
  Input,
  Card,
  Table,
  Modal,
  Form,
  message,
  Popconfirm,
  Tag,
} from "antd";
import {
  EditOutlined,
  SearchOutlined,
  PlusCircleOutlined,
  LeftOutlined,
  RightOutlined,
  DeleteOutlined,
  ReloadOutlined,
  EnterOutlined,
  // EyeOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
// import { selectFunnel } from "../../redux/slices/funnelSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  funnelRowActions,
  selectFunnelRow,
} from "../../redux/slices/funnelRowSlice";
import AddCompetitionForm from "../../sections/FunnelRowManage/AddCompetitionForm";
import {
  addFunnelRowApi,
  deleteFunnelRowApi,
  editFunnelRowApi,
} from "../../redux/apis/dashboardApi";
import AddYourProductForm from "../../sections/FunnelRowManage/AddYourProductForm";
import TotalPotentialValueForm from "../../sections/FunnelRowManage/TotalPotentialValueForm";
import TrialsReportsForm from "../../sections/FunnelRowManage/TrialsReportsForm";
// import ExistingSaleForm from "../../sections/FunnelRowManage/ExistingSaleForm";
import { selectLoggedUser } from "../../redux/slices/authSlice";
import ViewCustomerDetailsPopup from "../../sections/FunnelRowManage/ViewCustomerDetailsPopup";
import AddEditFunnelRow from "../../sections/FunnelRowManage/AddEditFunnelRow";
import AddConversionSaleValue from "../../sections/FunnelRowManage/AddConversionSaleValue";
import AddTimelineDate from "../../sections/FunnelRowManage/AddTimelineDate";
import AddOrderValuesForm from "../../sections/FunnelRowManage/AddOrderValuesForm";
import AddRemarks from "../../sections/FunnelRowManage/AddRemarks";
import AddInquiryForm from "../../sections/FunnelRowManage/AddInquiryForm";
import "./funnel.css";
import CarryForwardHistoryTable from "../../sections/FunnelRowManage/CarryForwardHistoryTable";
import AddProgressStagesFrom from "../../sections/FunnelRowManage/AddProgressStagesFrom";
import { PATH_DASHBOARD } from "../../routes/path";
import { renderPresetWisePrice } from "../../utils/shared/presetWiseValue";

const FunnelRowList = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const loggedUser = useSelector(selectLoggedUser);
  const funnelData = loggedUser?.activeFunnel;
  const funnelRowData = useSelector(selectFunnelRow);

  const presetsData = loggedUser?.presets;

  const viewFunnelData = funnelData;
  const [addCompetitionForm] = Form.useForm();
  const [addYourProductForm] = Form.useForm();
  const [totalPotentialForm] = Form.useForm();
  const [trialsReportsForm] = Form.useForm();
  // const [existingSaleForm] = Form.useForm();
  const [conersionSaleValueForm] = Form.useForm();
  const [timelineAddForm] = Form.useForm();
  const [orderValueForm] = Form.useForm();
  const [remarksForm] = Form.useForm();
  const [addInquiryForm] = Form.useForm();
  const [progressStageForm] = Form.useForm();

  const columns = [
    {
      title: <span style={{ fontSize: "12px" }}>Customer Name</span>,
      dataIndex: "customerId",
      fixed: "left",
      width: 120,
      render: (_, elm) => (
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <span
            style={{
              cursor: "pointer",
              color: "blue",
              textDecoration: "underline",
              wordBreak: "break-all",
            }}
            onClick={() => handleViewCustomerDetails(elm)}
          >
            {elm?.customerId ? elm?.customerId?.name : "-"}
          </span>

          <span
            style={{ color: "black", fontSize: "12px", wordBreak: "break-all" }}
          >
            {elm?.customerId?.isNewProject ? "( New Project )" : ""}
          </span>
        </div>
      ),
    },
    {
      title: <span style={{ fontSize: "12px" }}>Location</span>,
      dataIndex: "location",
      width: 120,
      render: (val) => (
        <span>{val?.length > 20 ? `${val.slice(0, 29)}...` : val}</span>
      ),
    },
    {
      title: <span style={{ fontSize: "12px" }}>Organisation Chart</span>,
      dataIndex: "organtionChart",
      width: 120,
      render: (_, elm) => (
        <Button
          onClick={() =>
            navigate(PATH_DASHBOARD.viewOrganizationChart(elm?.customerId?._id))
          }
        >
          View
        </Button>
      ),
    },
    {
      title: <span style={{ fontSize: "12px" }}>Progress Stage</span>,
      dataIndex: "progressStage",
      width: 180,
      render: (val, elm) => {
        const currentDate = dayjs().startOf("day");
        const targetDate = dayjs(elm?.conversionTimeline);
        const diffInDays = targetDate.diff(currentDate, "day");

        return (
          <>
            {elm?.conversionTimeline && diffInDays <= 0 ? (
              <>
                <Tag style={{ color: "black" }} color={val?.bg}>
                  {val?.value} {val?.name}
                </Tag>{" "}
              </>
            ) : val ? (
              <Tag
                color={val?.bg}
                style={{ cursor: "pointer", color: "#000000" }}
                onClick={() => handleProgressStages(elm, "edit")}
              >
                {val?.name}
              </Tag>
            ) : (
              <Tag
                style={{ cursor: "pointer" }}
                onClick={() => handleProgressStages(elm, "add")}
              >
                + Add Stage
              </Tag>
            )}
          </>
        );
      },
    },
    {
      title: (
        <span style={{ fontSize: "12px" }}>
          Competition Brand & Product Details
        </span>
      ),
      dataIndex: "competitionBrand",
      width: 120,
      render: (val, elm) => {
        const currentDate = dayjs().startOf("day");

        const targetDate = dayjs(elm?.conversionTimeline);
        const diffInDays = targetDate.diff(currentDate, "day");

        return (
          <>
            {elm?.conversionTimeline && diffInDays <= 0 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    gap: 2,
                    wordBreak: "break-all",
                  }}
                >
                  <div>
                    {val &&
                      Object.keys(val).map((keys) => (
                        <>
                          <span style={{ margin: 0 }}>
                            {val[keys]?.length > 12
                              ? `${val[keys]?.slice(0, 12)}...`
                              : val[keys]}
                            ,
                          </span>
                        </>
                      ))}
                  </div>
                </div>
              </>
            ) : val ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    gap: 2,
                    wordBreak: "break-all",
                  }}
                >
                  <div>
                    {Object.keys(val).map((keys) => (
                      <>
                        <span style={{ margin: 0 }}>
                          {val[keys]?.length > 12
                            ? `${val[keys]?.slice(0, 12)}...`
                            : val[keys]}
                          ,
                        </span>
                      </>
                    ))}
                  </div>
                  <Button
                    size="small"
                    icon={<EditOutlined />}
                    onClick={() => handleAddCompetition(elm, "edit")}
                  />
                </div>
              </>
            ) : (
              <>
                <span
                  onClick={() => handleAddCompetition(elm, "add")}
                  style={{ cursor: "pointer", color: "#2536b8" }}
                >
                  + Add Competition
                </span>
              </>
            )}
          </>
        );
      },
    },
    {
      title: (
        <span style={{ fontSize: "12px", textTransform: "capitalize" }}>
          {loggedUser?.activeFunnel?.funnelName || ""} Product Details against
          competition
        </span>
      ),
      dataIndex: "productAgaintCompetition",
      width: 120,
      render: (val, elm) => {
        const currentDate = dayjs().startOf("day");
        const targetDate = dayjs(elm?.conversionTimeline);
        const diffInDays = targetDate.diff(currentDate, "day");

        return (
          <>
            {elm?.conversionTimeline && diffInDays <= 0 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    gap: 2,
                    wordBreak: "break-all",
                  }}
                >
                  <div>
                    {val &&
                      Object.keys(val).map((keys) => (
                        <>
                          <span style={{ margin: 0 }}>
                            {val[keys]?.length > 12
                              ? `${val[keys]?.slice(0, 12)}...`
                              : val[keys]}
                            ,
                          </span>
                        </>
                      ))}
                  </div>
                </div>
              </>
            ) : val ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    gap: 2,
                    wordBreak: "break-all",
                  }}
                >
                  <div>
                    {Object.keys(val).map((keys) => (
                      <>
                        <span style={{ margin: 0 }}>
                          {val[keys]?.length > 12
                            ? `${val[keys]?.slice(0, 12)}...`
                            : val[keys]}
                          ,
                        </span>
                      </>
                    ))}
                  </div>
                  <Button
                    size="small"
                    icon={<EditOutlined />}
                    onClick={() => handleAddYourProduct(elm, "edit")}
                  />
                </div>
              </>
            ) : (
              <>
                <span
                  onClick={() => handleAddYourProduct(elm, "add")}
                  style={{ cursor: "pointer", color: "#2536b8" }}
                >
                  + Select or Add your product
                </span>
              </>
            )}
          </>
        );
      },
    },
    {
      title: (
        <span style={{ fontSize: "12px" }}>
          Total Potential Value (in {presetsData?.potentialValue ?? "-"})
        </span>
      ),
      dataIndex: "potentialValue",
      width: 120,
      render: (val, elm) => {
        const currentDate = dayjs().startOf("day");
        const targetDate = dayjs(elm?.conversionTimeline);
        const diffInDays = targetDate.diff(currentDate, "day");

        return (
          <>
            {elm?.conversionTimeline && diffInDays <= 0 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    wordBreak: "break-all",
                  }}
                >
                  <div>
                    {val?.total
                      ? renderPresetWisePrice(val.total, presetsData)
                      : "0"}
                  </div>
                </div>
              </>
            ) : val ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    wordBreak: "break-all",
                  }}
                >
                  <div>
                    {val?.total
                      ? renderPresetWisePrice(val.total, presetsData)
                      : "0"}
                  </div>
                  <Button
                    size="small"
                    icon={<EditOutlined />}
                    onClick={() => handleTotalPotential(elm, "edit")}
                  />
                </div>
              </>
            ) : (
              <>
                <span
                  onClick={() => handleTotalPotential(elm, "add")}
                  style={{ cursor: "pointer", color: "#2536b8" }}
                >
                  + Select or Add
                </span>
              </>
            )}{" "}
          </>
        );
      },
    },
    {
      title: (
        <>
          <div style={{ position: "relative", bottom: "20px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                icon={<LeftOutlined />}
                onClick={() => moveColumnLeft("inquiryValue")}
                style={{ marginRight: 4 }}
                size="small"
              />
              <Button
                icon={<RightOutlined />}
                onClick={() => moveColumnRight("inquiryValue")}
                style={{ marginLeft: 4 }}
                size="small"
              />
            </div>
            <span style={{ fontSize: "12px" }}>Inquiry Value If Any</span>
          </div>
        </>
      ),

      dataIndex: "inquiryValue",
      width: 120,
      render: (val, elm) => {
        const currentDate = dayjs().startOf("day");
        const targetDate = dayjs(elm?.conversionTimeline);
        const diffInDays = targetDate.diff(currentDate, "day");

        return (
          <>
            {elm?.conversionTimeline && diffInDays <= 0 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {val ? renderPresetWisePrice(val?.value, presetsData) : "0"}
                  </div>
                </div>
              </>
            ) : val ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {val ? renderPresetWisePrice(val?.value, presetsData) : "0"}
                  </div>
                  <Button
                    size="small"
                    icon={<EditOutlined />}
                    onClick={() => handleAddEditInquiry(elm, "edit")}
                  />
                </div>
              </>
            ) : (
              <>
                <span
                  onClick={() => handleAddEditInquiry(elm, "add")}
                  style={{ cursor: "pointer", color: "#2536b8" }}
                >
                  + Add
                </span>
              </>
            )}
          </>
        );
      },
    },
    {
      title: (
        <>
          <div style={{ position: "relative", bottom: "20px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                icon={<LeftOutlined />}
                onClick={() => moveColumnLeft("trialsReportsData")}
                style={{ marginRight: 4 }}
                size="small"
              />
              <Button
                icon={<RightOutlined />}
                onClick={() => moveColumnRight("trialsReportsData")}
                style={{ marginLeft: 4 }}
                size="small"
              />
            </div>
            <span style={{ fontSize: "12px" }}> Trials / Demo Reports</span>
          </div>
        </>
      ),
      dataIndex: "trialsReportsData",
      width: 120,
      render: (val, elm) => {
        const currentDate = dayjs().startOf("day");
        const targetDate = dayjs(elm?.conversionTimeline);
        const diffInDays = targetDate.diff(currentDate, "day");

        return (
          <>
            {elm?.conversionTimeline && diffInDays <= 0 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>{val?.length > 0 ? `${val?.length}` : "0"}</div>
                </div>
              </>
            ) : val?.length > 0 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>{val?.length > 0 ? `${val?.length}` : "0"}</div>
                  <Button
                    size="small"
                    icon={<EditOutlined />}
                    onClick={() => handleTrialReports(elm, "edit")}
                  />
                </div>
              </>
            ) : (
              <>
                <span
                  onClick={() => handleTrialReports(elm, "add")}
                  style={{ cursor: "pointer", color: "#2536b8" }}
                >
                  + Add
                </span>
              </>
            )}
          </>
        );
      },
    },
    {
      title: (
        <>
          <div style={{ position: "relative", bottom: "10px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                icon={<LeftOutlined />}
                onClick={() => moveColumnLeft("existingSaleData")}
                style={{ marginRight: 4 }}
                size="small"
              />
              <Button
                icon={<RightOutlined />}
                onClick={() => moveColumnRight("existingSaleData")}
                style={{ marginLeft: 4 }}
                size="small"
              />
            </div>
            <span style={{ fontSize: "12px", textTransform: "capitalize" }}>
              {loggedUser?.activeFunnel?.funnelName || ""} existing current sale
              value
            </span>
          </div>
        </>
      ),
      dataIndex: "existingSaleData",
      width: 120,
      render: (val, elm) => {
        return (
          <>
            {elm?.potentialValue ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {elm?.potentialValue
                      ? renderPresetWisePrice(
                          elm?.potentialValue?.existingSaleCurrentVolue,
                          presetsData
                        )
                      : "0"}
                  </div>
                </div>
              </>
            ) : (
              <>
                <span>0</span>
              </>
            )}
          </>
        );
      },
    },
    {
      title: (
        <>
          <div style={{ position: "relative", bottom: "10px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                icon={<LeftOutlined />}
                onClick={() => moveColumnLeft("conversionSale")}
                style={{ marginRight: 4 }}
                size="small"
              />
              <Button
                icon={<RightOutlined />}
                onClick={() => moveColumnRight("conversionSale")}
                style={{ marginLeft: 4 }}
                size="small"
              />
            </div>
            <span style={{ fontSize: "12px" }}>
              Conversion sale value plan (in{" "}
              {presetsData?.potentialValue ?? "-"})
            </span>
          </div>
        </>
      ),
      dataIndex: "conversionSale",
      width: 120,
      render: (val, elm) => {
        const currentDate = dayjs().startOf("day");
        const targetDate = dayjs(elm?.conversionTimeline);
        const diffInDays = targetDate.diff(currentDate, "day");

        let showingPrice = 0;

        if (val) {
          if (val < 1000) {
            showingPrice = val;
          } else {
            showingPrice = renderPresetWisePrice(val, presetsData);
          }
        }

        return (
          <>
            {elm?.conversionTimeline && diffInDays <= 0 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>{showingPrice}</div>
                </div>
              </>
            ) : val ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>{showingPrice}</div>
                  <Button
                    size="small"
                    icon={<EditOutlined />}
                    onClick={() => handleConversionSaleValue(elm, "edit")}
                  />
                </div>
              </>
            ) : (
              <>
                <span
                  onClick={() => handleConversionSaleValue(elm, "add")}
                  style={{ cursor: "pointer", color: "#2536b8" }}
                >
                  + Add
                </span>
              </>
            )}
          </>
        );
      },
    },
    {
      title: (
        <span style={{ fontSize: "12px" }}>Conversion Timeline (in days)</span>
      ),
      dataIndex: "conversionTimeline",
      width: 120,
      render: (val, elm) => (
        <>
          {val ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>{val ? dayjs(val).format("DD/MM/YYYY") : "-"}</div>
              </div>
            </>
          ) : (
            <>
              <span
                onClick={() => handleAddTimeline(elm, "add")}
                style={{ cursor: "pointer", color: "#2536b8" }}
              >
                + Add
              </span>
            </>
          )}
        </>
      ),
    },
    {
      title: <span style={{ fontSize: "12px" }}>Conversion days due</span>,
      dataIndex: "conversionDueDay",
      width: 120,
      render: (val, elm) => {
        const currentDate = dayjs(val).startOf("day");
        const targetDate = dayjs(elm?.conversionTimeline);
        const diffInDays = targetDate.diff(currentDate, "day");

        return (
          <>
            {elm?.conversionTimeline ? (
              <div>{diffInDays < 0 ? "0" : diffInDays} days left</div>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: (
        <span style={{ fontSize: "12px" }}>
          Order Value Conversion (in {presetsData?.potentialValue ?? "-"})
        </span>
      ),
      dataIndex: "orderValueTotal",
      width: 120,
      render: (val, elm) => {
        const currentDate = dayjs().startOf("day");
        const targetDate = dayjs(elm?.conversionTimeline);
        const diffInDays = targetDate.diff(currentDate, "day");

        let showingPrice = 0;

        if (val) {
          if (val < 1000) {
            showingPrice = val;
          } else {
            showingPrice = renderPresetWisePrice(val, presetsData);
          }
        }

        return (
          <>
            {elm?.conversionTimeline && diffInDays <= 0 ? (
              <>
                {" "}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>{showingPrice}</div>
                </div>
              </>
            ) : val ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>{showingPrice}</div>
                  <Button
                    size="small"
                    icon={<EditOutlined />}
                    onClick={() => handelOrderValues(elm, "edit")}
                  />
                </div>
              </>
            ) : (
              <>
                <span
                  onClick={() => handelOrderValues(elm, "add")}
                  style={{ cursor: "pointer", color: "#2536b8" }}
                >
                  + Add
                </span>
              </>
            )}
          </>
        );
      },
    },
    {
      title: (
        <span style={{ fontSize: "12px" }}>Conversion against plan %</span>
      ),
      dataIndex: "conversionAgainstPlan",
      width: 120,
      render: (val, elm) => (
        <>
          <div>
            {elm?.orderValueTotal && elm?.conversionSale
              ? ((elm?.orderValueTotal / elm?.conversionSale) * 100).toFixed(2)
              : 0}
            %
          </div>
        </>
      ),
    },
    {
      title: (
        <span style={{ fontSize: "12px", textTransform: "capitalize" }}>
          {loggedUser?.activeFunnel?.funnelName || ""} initial market share
        </span>
      ),
      dataIndex: "initialMarketShare",
      width: 120,
      render: (val, elm) => (
        <span>
          {elm?.potentialValue
            ? Number(
                (elm?.potentialValue?.existingSaleVolue /
                  elm?.potentialValue?.total) *
                  100
              ).toFixed(2)
            : 0}
          %
        </span>
      ),
    },
    {
      title: (
        <span style={{ fontSize: "12px", textTransform: "capitalize" }}>
          {loggedUser?.activeFunnel?.funnelName || ""} revised market share
        </span>
      ),
      dataIndex: "revisedMarketShare",
      width: 120,
      render: (val, elm) => (
        <span>
          {elm?.potentialValue && elm?.potentialValue?.total
            ? Number(
                (elm?.potentialValue?.existingSaleCurrentVolue /
                  elm?.potentialValue?.total) *
                  100
              ).toFixed(2)
            : 0}
          %
        </span>
      ),
    },
    {
      title: <span style={{ fontSize: "12px" }}>Remarks</span>,
      dataIndex: "remarks",
      width: 120,
      render: (val, elm) => {
        const currentDate = dayjs().startOf("day");
        const targetDate = dayjs(elm?.conversionTimeline);
        const diffInDays = targetDate.diff(currentDate, "day");
        return (
          <>
            {elm?.conversionTimeline && diffInDays <= 0 ? (
              <>
                {" "}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    wordBreak: "break-all",
                    gap: 2,
                  }}
                >
                  <div>
                    {val
                      ? `${val?.length > 15 ? `${val.slice(0, 12)}...` : val}`
                      : "-"}
                  </div>
                </div>
              </>
            ) : val ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 2,
                    wordBreak: "break-all",
                  }}
                >
                  <div>
                    {val
                      ? `${val?.length > 15 ? `${val.slice(0, 12)}...` : val}`
                      : "-"}
                  </div>
                  <div style={{ padding: "5px" }}>
                    <Button
                      size="small"
                      icon={<EditOutlined />}
                      onClick={() => handleAddEditRemarks(elm, "edit")}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <span
                  onClick={() => handleAddEditRemarks(elm, "add")}
                  style={{ cursor: "pointer", color: "#2536b8" }}
                >
                  + Add
                </span>
              </>
            )}
          </>
        );
      },
    },
    {
      title: <span style={{ fontSize: "12px" }}>Actions</span>,
      dataIndex: "actions",
      align: "center",
      fixed: "right",
      width: 120,
      render: (_, elm) => {
        const currentDate = dayjs().startOf("day");
        const targetDate = dayjs(elm?.conversionTimeline);
        const diffInDays = targetDate.diff(currentDate, "day");

        return (
          <>
            <div>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  gap: 10,
                }}
              >
                {elm?.conversionTimeline && diffInDays <= 0 ? (
                  <Tooltip title="Carry Forward">
                    <Button
                      className="mr-2"
                      type="primary"
                      size="middle"
                      loading={submitData?.loading}
                      icon={<EnterOutlined style={{ fontSize: "16px" }} />}
                      onClick={() => handleCarryForwardRow(elm)}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Edit">
                    <Button
                      className="mr-2"
                      icon={<EditOutlined style={{ fontSize: "16px" }} />}
                      type="primary"
                      size="middle"
                      onClick={() => handleEditFunnelRow(elm)}
                    />
                  </Tooltip>
                )}

                <Popconfirm
                  title="Delete the funnel row"
                  description={
                    <>
                      <span>Are you sure want to delete this funnel row?</span>
                    </>
                  }
                  onConfirm={() => handleDelete(elm._id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button danger icon={<DeleteOutlined />} size="middle" />
                </Popconfirm>
              </div>
              {elm?.isHistory === true && (
                <div style={{ marginTop: "10px" }}>
                  <span
                    style={{
                      fontSize: "12px",
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => handleViewHistory(elm)}
                  >
                    View History
                  </span>
                </div>
              )}
            </div>
          </>
        );
      },
    },
  ];

  const [funnelRows, setFunnelRows] = useState([]);
  const [funnelRowsModel, setFunnelRowsModel] = useState({
    data: null,
    model: false,
    type: null,
  });
  const [viewCustomerDetails, setViewCustomerDetails] = useState({
    data: null,
    model: false,
  });
  const [columnOrder, setColumnOrder] = useState(
    columns.map((col) => col.dataIndex)
  );
  const [columnVisibility, setColumnVisibility] = useState(
    columns.reduce((acc, col) => {
      acc[col.dataIndex] = true;
      return acc;
    }, {})
  );

  const [isColumnModalVisible, setIsColumnModalVisible] = useState(false);

  // add competition model state
  const [addCompetitionModel, setAddCompetitionModel] = useState(false);

  // add your product model state
  const [addYourProductModel, setAddYourProductModel] = useState(false);

  // total potential
  const [totalPotentialModel, setTotalPotentialModel] = useState(false);

  // trial reports
  const [trialsReportsModel, setTrialsReportsModel] = useState(false);

  // existing sale
  // const [existingSaleModel, setExisingSaleModel] = useState(false);

  // conversion sale value
  const [conversionSaleValueModel, setConversionSaleValueModel] =
    useState(false);

  // conversion time line
  const [timeLineModel, setTimeLineModel] = useState(false);

  // order values
  const [orderValuesModel, setOrderValuesModel] = useState(false);

  // remarks
  const [remarksModel, setRemarksModel] = useState(false);

  // remarks
  const [addInquiryModel, setAddInquiryModel] = useState(false);

  // progress stage
  const [progressStageModel, setProgressStageModel] = useState(false);

  // carryforward
  const [carryHistory, setCarryHistory] = useState({
    row: null,
    model: false,
  });

  const [submitData, setSubmitData] = useState({
    id: null,
    loading: false,
    row: null,
  });

  const moveColumnLeft = (dataIndex) => {
    const currentIndex = columnOrder.indexOf(dataIndex);
    if (currentIndex > 0 && currentIndex !== 7) {
      const newOrder = [...columnOrder];
      newOrder.splice(currentIndex, 1);
      newOrder.splice(currentIndex - 1, 0, dataIndex);
      setColumnOrder(newOrder);
    }
  };

  const moveColumnRight = (dataIndex) => {
    const currentIndex = columnOrder.indexOf(dataIndex);
    if (currentIndex < columnOrder.length - 1 && currentIndex !== 10) {
      const newOrder = [...columnOrder];
      newOrder.splice(currentIndex, 1);
      newOrder.splice(currentIndex + 1, 0, dataIndex);
      setColumnOrder(newOrder);
    }
  };

  const reorderedColumns = columns.sort((a, b) => {
    return columnOrder.indexOf(a.dataIndex) - columnOrder.indexOf(b.dataIndex);
  });

  useEffect(() => {
    if (Object.keys(viewFunnelData).length > 0 && viewFunnelData?._id) {
      dispatch(
        funnelRowActions.funnelRowList({
          limit: 10,
          page: 1,
          funnelId: viewFunnelData?._id,
          userId: loggedUser?._id,
          isDelete: false,
          isCarryForward: false,
        })
      );
    }
    // eslint-disable-next-line
  }, [viewFunnelData]);

  const searchInData = (e) => {
    const searchInput = e.target.value;

    dispatch(
      funnelRowActions.funnelRowSearch({
        searchText: searchInput,
      })
    );
  };

  // eslint-disable-next-line
  const debounceOnSearch = React.useCallback(
    helperMethods.debounce(searchInData, 400),
    []
  );

  useEffect(() => {
    if (funnelRowData) {
      setFunnelRows(funnelRowData?.list);
    }
  }, [funnelRowData]);

  const handleRereshRow = () => {
    dispatch(
      funnelRowActions.funnelRowList({
        limit: 10,
        page: 1,
        funnelId: viewFunnelData?._id,
        userId: loggedUser?._id,
        isDelete: false,
        isCarryForward: false,
      })
    );
  };

  const handleDelete = async (id) => {
    try {
      const deleteRes = await deleteFunnelRowApi(id);
      if (deleteRes?.data?.success) {
        message.success(deleteRes?.data?.message);
        dispatch(
          funnelRowActions.funnelRowList({
            limit: 10,
            page: 1,
            funnelId: viewFunnelData?._id,
            userId: loggedUser?._id,
            isDelete: false,
            isCarryForward: false,
          })
        );
      } else {
        message.error(deleteRes?.data?.message);
      }
    } catch (error) {
      console.log("error", error);
      message.error(error?.response.data?.message);
    }
  };

  const showColumnModal = () => {
    setIsColumnModalVisible(true);
  };

  const hideColumnModal = () => {
    setIsColumnModalVisible(false);
  };

  const toggleColumnVisibility = (dataIndex) => {
    setColumnVisibility((prevVisibility) => ({
      ...prevVisibility,
      [dataIndex]: !prevVisibility[dataIndex],
    }));
  };

  const visibleColumns = reorderedColumns.filter(
    (col) => columnVisibility[col.dataIndex]
  );

  let rightsWiseColumnsShow = [...visibleColumns];

  if (
    loggedUser?.addEditDeleteRights?.length > 0 &&
    loggedUser?.addEditDeleteRights
      ?.filter((v) => v.value === "funnel")
      .some((val) => val?.isAddEditDelete === true)
  ) {
    rightsWiseColumnsShow = [...rightsWiseColumnsShow];
  } else {
    delete rightsWiseColumnsShow[rightsWiseColumnsShow.length - 1];
  }

  // add funnel row
  const handleAddFunnelRow = () => {
    setFunnelRowsModel({
      data: null,
      model: true,
      type: "New",
    });
  };

  const handleEditFunnelRow = (row) => {
    setFunnelRowsModel({
      data: row,
      model: true,
      type: "Edit",
    });
  };

  // view customer details
  const handleViewCustomerDetails = (row) => {
    if (row?.customerId) {
      setViewCustomerDetails({ data: row?.customerId, model: true });
    }
  };

  // const hidingColumnsMovable = visibleColumns.map((col, index) => {
  //   if (index === 7) {
  //     return {
  //       ...col,
  //       title: (
  //         <>
  //           <div style={{ display: "flex", justifyContent: "space-between" }}>
  //             {/* Hide left arrow */}
  //             <Button
  //               icon={<RightOutlined />}
  //               onClick={() => moveColumnRight(col.dataIndex)}
  //               style={{ marginLeft: 4 }}
  //               size="small"
  //             />
  //           </div>
  //           <span style={{ fontSize: "12px" }}>{col.title}</span>
  //         </>
  //       ),
  //     };
  //   } else if (index === 10) {
  //     return {
  //       ...col,
  //       title: (
  //         <>
  //           <div style={{ display: "flex", justifyContent: "space-between" }}>
  //             {/* Hide right arrow */}
  //             <Button
  //               icon={<LeftOutlined />}
  //               onClick={() => moveColumnLeft(col.dataIndex)}
  //               style={{ marginRight: 4 }}
  //               size="small"
  //             />
  //           </div>
  //           <span style={{ fontSize: "12px" }}>{col.title}</span>
  //         </>
  //       ),
  //     };
  //   } else {
  //     return col;
  //   }
  // });

  // add competition model

  const handleAddCompetition = (row, type) => {
    setAddCompetitionModel(true);
    setSubmitData({ ...submitData, id: row?._id, row });

    if (type === "edit") {
      addCompetitionForm?.setFieldValue("name", row?.competitionBrand?.name);
      addCompetitionForm?.setFieldValue(
        "productSpecs",
        row?.competitionBrand?.productSpecs
      );
      addCompetitionForm?.setFieldValue("price", row?.competitionBrand?.price);
    }
  };

  const handleSubmitCompetition = async (values) => {
    try {
      if (values && submitData?.id) {
        setSubmitData({ ...submitData, loading: true });
        const data = {
          competitionBrand: {
            ...values,
          },
        };
        const res = await editFunnelRowApi(submitData?.id, data);

        if (res?.data?.success) {
          setSubmitData({ ...submitData, id: null, loading: false });
          message.success(res?.data?.message);
          setAddCompetitionModel(false);
          addCompetitionForm.resetFields();
          dispatch(
            funnelRowActions.funnelRowList({
              limit: 10,
              page: 1,
              funnelId: viewFunnelData?._id,
              userId: loggedUser?._id,
              isDelete: false,
              isCarryForward: false,
            })
          );
        } else {
          setSubmitData({ ...submitData, loading: false });
          message.error(res?.data?.message);
        }
      } else {
        setSubmitData({ ...submitData, loading: false });
        message.error("Something went wrong");
      }
    } catch (error) {
      setSubmitData({ ...submitData, loading: false });
      message.error(error?.response?.data?.message);
    }
  };

  // add your proudct
  const handleAddYourProduct = (row, type) => {
    setAddYourProductModel(true);
    setSubmitData({ ...submitData, id: row?._id, row });

    if (type === "edit") {
      addYourProductForm?.setFieldValue(
        "name",
        row?.productAgaintCompetition?.name
      );
      addYourProductForm?.setFieldValue(
        "productSpecs",
        row?.productAgaintCompetition?.productSpecs
      );
      addYourProductForm?.setFieldValue(
        "price",
        row?.productAgaintCompetition?.price
      );
    }
  };

  const handleSubmitYourProduct = async (values) => {
    try {
      if (values && submitData?.id) {
        setSubmitData({ ...submitData, loading: true });
        const data = {
          productAgaintCompetition: {
            ...values,
          },
        };
        const res = await editFunnelRowApi(submitData?.id, data);

        if (res?.data?.success) {
          setSubmitData({ ...submitData, id: null, loading: false });
          message.success(res?.data?.message);
          setAddYourProductModel(false);
          addYourProductForm.resetFields();
          dispatch(
            funnelRowActions.funnelRowList({
              limit: 10,
              page: 1,
              funnelId: viewFunnelData?._id,
              userId: loggedUser?._id,
              isDelete: false,
              isCarryForward: false,
            })
          );
        } else {
          setSubmitData({ ...submitData, loading: false });
          message.error(res?.data?.message);
        }
      } else {
        setSubmitData({ ...submitData, loading: false });
        message.error("Something went wrong");
      }
    } catch (error) {
      setSubmitData({ ...submitData, loading: false });
      message.error(error?.response?.data?.message);
    }
  };

  // total potential
  const handleTotalPotential = (row, type) => {
    setTotalPotentialModel(true);
    setSubmitData({ ...submitData, id: row?._id, row });

    if (type === "edit") {
      totalPotentialForm?.setFieldValue(
        "competitionSelection",
        row?.potentialValue?.competitionSelection
      );
      totalPotentialForm?.setFieldValue(
        "competitionVolume",
        row?.potentialValue?.competitionVolume
      );
      totalPotentialForm?.setFieldValue(
        "compititionUnitValue",
        row?.potentialValue?.compititionUnitValue
      );
      totalPotentialForm?.setFieldValue(
        "competitionPrice",
        row?.potentialValue?.competitionPrice
      );
      totalPotentialForm?.setFieldValue(
        "orderConversionValue",
        row?.potentialValue?.orderConversionValue
      );
      totalPotentialForm?.setFieldValue(
        "currentCoversionValue",
        row?.potentialValue?.currentCoversionValue ??
          Number(
            Number(row?.potentialValue?.orderConversionValue) -
              Number(row?.orderValueTotal)
          )
      );
      totalPotentialForm?.setFieldValue(
        "product",
        row?.potentialValue?.product
      );
      totalPotentialForm?.setFieldValue(
        "existingSaleVolume",
        row?.potentialValue?.existingSaleVolume
      );
      totalPotentialForm?.setFieldValue(
        "existingSaleUnitValue",
        row?.potentialValue?.existingSaleUnitValue
      );
      totalPotentialForm?.setFieldValue(
        "productPrice",
        row?.potentialValue?.productPrice
      );
      totalPotentialForm?.setFieldValue(
        "existingSaleVolue",
        row?.potentialValue?.existingSaleVolue
      );
      totalPotentialForm?.setFieldValue(
        "existingSaleCurrentVolue",
        row?.potentialValue?.existingSaleCurrentVolue ??
          Number(
            Number(row?.potentialValue?.existingSaleVolue) +
              Number(row?.orderValueTotal)
          )
      );
      totalPotentialForm?.setFieldValue("total", row?.potentialValue?.total);
    }
  };

  const handleSubmitPotential = async (values) => {
    try {
      if (values && submitData?.id) {
        setSubmitData({ ...submitData, loading: true });

        let orderTotal = submitData?.row?.orderValueTotal ?? 0;

        const data = {
          potentialValue: {
            ...values,
            currentCoversionValue:
              values?.currentCoversionValue ??
              Number(Number(values?.orderConversionValue) - Number(orderTotal)),
            existingSaleCurrentVolue:
              values?.existingSaleCurrentVolue ??
              Number(Number(values?.existingSaleVolue) + Number(orderTotal)),
          },
        };

        const res = await editFunnelRowApi(submitData?.id, data);

        if (res?.data?.success) {
          setSubmitData({ ...submitData, id: null, loading: false });
          message.success(res?.data?.message);
          setTotalPotentialModel(false);
          addYourProductForm.resetFields();
          dispatch(
            funnelRowActions.funnelRowList({
              limit: 10,
              page: 1,
              funnelId: viewFunnelData?._id,
              userId: loggedUser?._id,
              isDelete: false,
              isCarryForward: false,
            })
          );
        } else {
          setSubmitData({ ...submitData, loading: false });
          message.error(res?.data?.message);
        }
      } else {
        setSubmitData({ ...submitData, loading: false });
        message.error("Something went wrong");
      }
    } catch (error) {
      setSubmitData({ ...submitData, loading: false });
      message.error(error?.response?.data?.message);
    }
  };

  // trial reports
  const handleTrialReports = (row, type) => {
    setTrialsReportsModel(true);
    setSubmitData({ ...submitData, id: row?._id, row });

    if (type === "edit") {
      const initialTrialsReportsData = row?.trialsReportsData || [];

      trialsReportsForm?.setFieldsValue({
        fields: initialTrialsReportsData,
      });
    }
  };

  const handleSubmitTrialsReports = async (values) => {
    try {
      if (values && submitData?.id) {
        setSubmitData({ ...submitData, loading: true });
        const data = {
          trialsReportsData: values?.fields,
        };

        const res = await editFunnelRowApi(submitData?.id, data);

        if (res?.data?.success) {
          setSubmitData({ ...submitData, id: null, loading: false });
          message.success(res?.data?.message);
          setTrialsReportsModel(false);
          addYourProductForm.resetFields();
          dispatch(
            funnelRowActions.funnelRowList({
              limit: 10,
              page: 1,
              funnelId: viewFunnelData?._id,
              userId: loggedUser?._id,
              isDelete: false,
              isCarryForward: false,
            })
          );
        } else {
          setSubmitData({ ...submitData, loading: false });
          message.error(res?.data?.message);
        }
      } else {
        setSubmitData({ ...submitData, loading: false });
        message.error("Something went wrong");
      }
    } catch (error) {
      setSubmitData({ ...submitData, loading: false });
      message.error(error?.response?.data?.message);
    }
  };

  // existing value
  // const handleExistingSale = (row, type) => {
  //   setExisingSaleModel(true);
  //   setSubmitData({ ...submitData, id: row?._id, row });

  //   existingSaleForm?.setFieldsValue({
  //     product: row?.productAgaintCompetition?.name,
  //     details: row?.existingSaleData?.details,
  //     initialValue: row?.productAgaintCompetition?.price,
  //     orders: row?.orderValueTotal ?? 0,
  //     total:
  //       row?.orderValueTotal > 0
  //         ? row?.productAgaintCompetition?.price + row?.orderValueTotal
  //         : row?.productAgaintCompetition?.price,
  //   });
  // };

  // const handleSubmitExistingSale = async (values) => {
  //   try {
  //     if (values && submitData?.id) {
  //       if (submitData?.row?.orderValueTotal > 0) {
  //         setSubmitData({ ...submitData, loading: true });
  //         const data = {
  //           existingSaleData: {
  //             ...values,
  //             orders: submitData?.row?.orderValueTotal,
  //           },
  //         };

  //         const res = await editFunnelRowApi(submitData?.id, data);

  //         if (res?.data?.success) {
  //           setSubmitData({ ...submitData, id: null, loading: false });
  //           message.success(res?.data?.message);
  //           setExisingSaleModel(false);
  //           existingSaleForm.resetFields();
  //           dispatch(
  //             funnelRowActions.funnelRowList({
  //               limit: 10,
  //               page: 1,
  //               funnelId: viewFunnelData?._id,
  //               userId: loggedUser?._id,
  //               isDelete: false,
  //               isCarryForward: false,
  //             })
  //           );
  //         } else {
  //           setSubmitData({ ...submitData, loading: false });
  //           message.error(res?.data?.message);
  //         }
  //       } else {
  //         message.error("Please Enter First Order Value");
  //       }
  //     } else {
  //       setSubmitData({ ...submitData, loading: false });
  //       message.error("Something went wrong");
  //     }
  //   } catch (error) {
  //     setSubmitData({ ...submitData, loading: false });
  //     message.error(error?.response?.data?.message);
  //   }
  // };

  // conversion sale value
  const handleConversionSaleValue = (row, type) => {
    setConversionSaleValueModel(true);
    setSubmitData({ ...submitData, id: row?._id, row });

    if (type === "edit") {
      conersionSaleValueForm?.setFieldsValue({
        conversionSale: row?.conversionSale,
      });
    }
  };

  const handleSubmitCoversionSaleValue = async (values) => {
    try {
      if (values && submitData?.id) {
        setSubmitData({ ...submitData, loading: true });
        const data = {
          ...values,
        };

        const res = await editFunnelRowApi(submitData?.id, data);

        if (res?.data?.success) {
          setSubmitData({ ...submitData, id: null, loading: false });
          message.success(res?.data?.message);
          setConversionSaleValueModel(false);
          conersionSaleValueForm.resetFields();
          dispatch(
            funnelRowActions.funnelRowList({
              limit: 10,
              page: 1,
              funnelId: viewFunnelData?._id,
              userId: loggedUser?._id,
              isDelete: false,
              isCarryForward: false,
            })
          );
        } else {
          setSubmitData({ ...submitData, loading: false });
          message.error(res?.data?.message);
        }
      } else {
        setSubmitData({ ...submitData, loading: false });
        message.error("Something went wrong");
      }
    } catch (error) {
      setSubmitData({ ...submitData, loading: false });
      message.error(error?.response?.data?.message);
    }
  };

  // conversion sale value
  const handleAddTimeline = (row, type) => {
    setTimeLineModel(true);
    setSubmitData({ ...submitData, id: row?._id, row });
  };

  const handleSubmitTimeLine = async (values) => {
    try {
      if (values && submitData?.id) {
        setSubmitData({ ...submitData, loading: true });
        const data = {
          conversionTimeline: new Date(values?.conversionTimeline).setHours(
            0,
            0,
            0,
            0
          ),
        };

        const res = await editFunnelRowApi(submitData?.id, data);

        if (res?.data?.success) {
          setSubmitData({ ...submitData, id: null, loading: false });
          message.success(res?.data?.message);
          setTimeLineModel(false);
          timelineAddForm.resetFields();
          dispatch(
            funnelRowActions.funnelRowList({
              limit: 10,
              page: 1,
              funnelId: viewFunnelData?._id,
              userId: loggedUser?._id,
              isDelete: false,
              isCarryForward: false,
            })
          );
        } else {
          setSubmitData({ ...submitData, loading: false });
          message.error(res?.data?.message);
        }
      } else {
        setSubmitData({ ...submitData, loading: false });
        message.error("Something went wrong");
      }
    } catch (error) {
      setSubmitData({ ...submitData, loading: false });
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  // order values
  const handelOrderValues = (row, type) => {
    setOrderValuesModel(true);
    setSubmitData({ ...submitData, id: row?._id, row });

    if (type === "edit") {
      const intiialOrderValues = row?.orderValueData || [];

      if (intiialOrderValues?.length > 0) {
        const modifiedData = intiialOrderValues?.map((item) => ({
          ...item,
          orderDate: item?.orderDate ? dayjs(item?.orderDate) : null,
          product: row?.productAgaintCompetition?.name || "",
        }));

        orderValueForm?.setFieldsValue({
          orderFields: modifiedData,
        });
      }
    }
  };

  const handleSubmitOrderValues = async (values) => {
    try {
      if (values && values?.orderFields?.length > 0 && submitData?.id) {
        setSubmitData({ ...submitData, loading: true });

        const orderValueTotal = values?.orderFields?.reduce(
          (total, item) => total + Number(item?.orderValue),
          0
        );

        const modifiedData = values?.orderFields?.map((item) => ({
          ...item,
          product: submitData?.row?.productAgaintCompetition?.name || "",
        }));

        const data = {
          orderValueData: modifiedData,
          orderValueTotal,
          existingSaleData: {
            product: submitData?.row?.productAgaintCompetition?.name ?? "",
            details: submitData?.row?.existingSaleData?.details ?? "",
            initialValue: submitData?.row?.productAgaintCompetition?.price ?? 0,
            orders: orderValueTotal,
            total:
              orderValueTotal > 0
                ? submitData?.row?.productAgaintCompetition?.price +
                  orderValueTotal
                : submitData?.row?.productAgaintCompetition?.price,
          },
          potentialValue: {
            ...submitData?.row?.potentialValue,
            existingSaleCurrentVolue: Number(
              Number(submitData?.row?.potentialValue?.existingSaleVolue) +
                Number(orderValueTotal)
            ),
            currentCoversionValue: Number(
              Number(submitData?.row?.potentialValue?.orderConversionValue) -
                Number(orderValueTotal)
            ),
          },
        };

        // if (orderValueTotal <= submitData?.row?.conversionSale) {
        const res = await editFunnelRowApi(submitData?.id, data);

        if (res?.data?.success) {
          setSubmitData({ ...submitData, id: null, loading: false });
          message.success(res?.data?.message);
          setOrderValuesModel(false);
          setTimeout(() => {
            orderValueForm.resetFields();
            dispatch(
              funnelRowActions.funnelRowList({
                limit: 10,
                page: 1,
                funnelId: viewFunnelData?._id,
                userId: loggedUser?._id,
                isDelete: false,
                isCarryForward: false,
              })
            );
          }, 800);
        } else {
          setSubmitData({ ...submitData, loading: false });
          message.error(res?.data?.message);
        }
        // } else {
        //   setSubmitData({ ...submitData, loading: false });
        //   message.error(
        //     `Please set the order value under conversion sale value (${submitData?.row?.conversionSale})`
        //   );
        // }
      } else {
        setSubmitData({ ...submitData, loading: false });
        message.error("Something went wrong");
      }
    } catch (error) {
      setSubmitData({ ...submitData, loading: false });
      message.error(error?.response?.data?.message);
    }
  };

  // remarks
  const handleAddEditRemarks = (row, type) => {
    setRemarksModel(true);
    setSubmitData({ ...submitData, id: row?._id, row });

    if (type === "edit") {
      remarksForm?.setFieldsValue({
        remarks: row?.remarks,
      });
    }
  };

  const handleSubmitRemarks = async (values) => {
    try {
      if (values && submitData?.id) {
        setSubmitData({ ...submitData, loading: true });
        const data = {
          ...values,
        };

        const res = await editFunnelRowApi(submitData?.id, data);

        if (res?.data?.success) {
          setSubmitData({ ...submitData, id: null, loading: false });
          message.success(res?.data?.message);
          setRemarksModel(false);
          remarksForm.resetFields();
          dispatch(
            funnelRowActions.funnelRowList({
              limit: 10,
              page: 1,
              funnelId: viewFunnelData?._id,
              userId: loggedUser?._id,
              isDelete: false,
              isCarryForward: false,
            })
          );
        } else {
          setSubmitData({ ...submitData, loading: false });
          message.error(res?.data?.message);
        }
      } else {
        setSubmitData({ ...submitData, loading: false });
        message.error("Something went wrong");
      }
    } catch (error) {
      setSubmitData({ ...submitData, loading: false });
      message.error(error?.response?.data?.message);
    }
  };

  // add inquiry
  const handleAddEditInquiry = (row, type) => {
    setAddInquiryModel(true);
    setSubmitData({ ...submitData, id: row?._id, row });

    addInquiryForm?.setFieldsValue({
      product: row?.productAgaintCompetition?.name,
      qty: row?.inquiryValue?.qty,
      unit: loggedUser?.presets?.unitValue,
      price: row?.productAgaintCompetition?.price || 0,
      value: row?.inquiryValue?.value ?? row?.productAgaintCompetition?.price,
    });
  };

  const handleSubmitInquiry = async (values) => {
    try {
      if (values && submitData?.id) {
        setSubmitData({ ...submitData, loading: true });
        const data = {
          inquiryValue: values,
        };

        const res = await editFunnelRowApi(submitData?.id, data);

        if (res?.data?.success) {
          setSubmitData({ ...submitData, id: null, loading: false });
          message.success(res?.data?.message);
          setAddInquiryModel(false);
          addInquiryForm.resetFields();
          dispatch(
            funnelRowActions.funnelRowList({
              limit: 10,
              page: 1,
              funnelId: viewFunnelData?._id,
              userId: loggedUser?._id,
              isDelete: false,
              isCarryForward: false,
            })
          );
        } else {
          setSubmitData({ ...submitData, loading: false });
          message.error(res?.data?.message);
        }
      } else {
        setSubmitData({ ...submitData, loading: false });
        message.error("Something went wrong");
      }
    } catch (error) {
      setSubmitData({ ...submitData, loading: false });
      message.error(error?.response?.data?.message);
    }
  };

  // add progress stages
  const handleProgressStages = (row, type) => {
    setProgressStageModel(true);
    setSubmitData({ ...submitData, id: row?._id, row });

    if (type === "edit") {
      progressStageForm?.setFieldsValue({
        progressStage: row?.progressStage?.value,
      });
    }
  };

  const handleSubmitProgressStage = async (values) => {
    try {
      if (values && submitData?.id) {
        setSubmitData({ ...submitData, loading: true });

        const selectedStage = loggedUser?.stages?.find(
          (v, index) => index + 1 === values?.progressStage
        );

        const data = {
          progressStage: selectedStage,
        };

        const res = await editFunnelRowApi(submitData?.id, data);

        if (res?.data?.success) {
          setSubmitData({ ...submitData, id: null, loading: false });
          message.success(res?.data?.message);
          setProgressStageModel(false);
          progressStageForm.resetFields();
          dispatch(
            funnelRowActions.funnelRowList({
              limit: 10,
              page: 1,
              funnelId: viewFunnelData?._id,
              userId: loggedUser?._id,
              isDelete: false,
              isCarryForward: false,
            })
          );
        } else {
          setSubmitData({ ...submitData, loading: false });
          message.error(res?.data?.message);
        }
      } else {
        setSubmitData({ ...submitData, loading: false });
        message.error("Something went wrong");
      }
    } catch (error) {
      setSubmitData({ ...submitData, loading: false });
      message.error(error?.response?.data?.message);
    }
  };

  // disable row
  const disableRow = (record) => {
    const currentDate = dayjs().startOf("day");
    const targetDate = dayjs(record?.conversionTimeline);
    const diffInDays = targetDate.diff(currentDate, "day");

    if (record?.conversionTimeline && diffInDays <= 0) {
      return true;
    }
    return false;
  };

  // add carry forward row
  const handleCarryForwardRow = async (rowData) => {
    try {
      setSubmitData({ ...submitData, loading: true });

      if (rowData) {
        const editData = {
          isCarryForward: true,
        };

        const editRes = await editFunnelRowApi(rowData?._id, editData);

        if (editRes?.data?.success) {
          const addCarryForwardRowData = {
            customerId: rowData?.customerId,
            location: rowData?.location,
            progressStage: rowData?.progressStage,
            competitionBrand: rowData?.competitionBrand,
            productAgaintCompetition: rowData?.productAgaintCompetition,
            potentialValue: rowData?.potentialValue,
            funnelId: loggedUser?.activeFunnel?._id,
            preset: loggedUser?.presets?._id,
            mainFunnelRow: rowData?.mainFunnelRow,
            isHistory: true,
          };

          const addRowRes = await addFunnelRowApi(addCarryForwardRowData);

          if (addRowRes?.data?.success) {
            message.success(addRowRes?.data?.message);
            setSubmitData({ ...submitData, loading: false });
            setTimeout(() => {
              dispatch(
                funnelRowActions.funnelRowList({
                  limit: 10,
                  page: 1,
                  funnelId: loggedUser?.activeFunnel?._id,
                  userId: loggedUser?._id,
                  isDelete: false,
                  isCarryForward: false,
                })
              );
            }, 1000);
          } else {
            setSubmitData({ ...submitData, loading: false });
            message.error(addRowRes?.data?.message);
          }
        } else {
          setSubmitData({ ...submitData, loading: false });
          message.error(editRes?.data?.message);
        }
      }
    } catch (error) {
      setSubmitData({ ...submitData, loading: false });
      message.error(error?.response?.data?.message ?? "Something went wrong!");
    }
  };

  // carry forward row history
  const handleViewHistory = (row) => {
    setCarryHistory({ row, model: true });
  };

  return (
    <>
      <div>
        <div
          style={{
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "5px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Input
              size="large"
              placeholder="Search..."
              prefix={<SearchOutlined />}
              onChange={(e) => {
                debounceOnSearch(e);
              }}
              allowClear
              onClear={(e) => debounceOnSearch()}
              style={{ width: "150px" }}
              disabled={Object.keys(funnelData).length > 0 ? false : true}
            />

            <Button
              size="large"
              style={{ marginLeft: "10px" }}
              onClick={showColumnModal}
            >
              Hide Columns
            </Button>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {Object.keys(funnelData).length > 0 ? (
              <span
                style={{
                  textAlign: "center",
                  fontSize: "24px",
                  fontWeight: 500,
                  textTransform: "capitalize",
                }}
              >
                {viewFunnelData?.funnelName ?? "-"} Funnel
              </span>
            ) : (
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  textTransform: "capitalize",
                  color: "red",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                No Active Funnel <br />
                <span style={{ fontSize: "12px" }}>
                  *Please Select or add funnel*
                </span>
              </span>
            )}
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <Tooltip title="Refresh Funnel Row">
              <Button
                onClick={handleRereshRow}
                icon={<ReloadOutlined />}
                size="large"
              />
            </Tooltip>
            {loggedUser?.addEditDeleteRights?.length > 0 &&
              loggedUser?.addEditDeleteRights
                ?.filter((v) => v.value === "createFunnel")
                .some((val) => val?.isAddEditDelete === true) && (
                <Button
                  type="primary"
                  icon={<PlusCircleOutlined />}
                  size="large"
                  onClick={() => handleAddFunnelRow()}
                  disabled={Object.keys(funnelData).length > 0 ? false : true}
                >
                  New Row
                </Button>
              )}
          </div>
        </div>
        <div className="table-responsive">
          <Card>
            <>
              <Table
                columns={rightsWiseColumnsShow}
                dataSource={funnelRows}
                loading={funnelRowData?.isLoading || false}
                rowClassName={(record) =>
                  disableRow(record) ? "disabled-row" : ""
                }
                rowKey="id"
                pagination={false}
                bordered
                scroll={{
                  x: 1500,
                  y: 400,
                }}
              />
              <div style={{ marginTop: "20px" }}>
                <CustomPagination />
              </div>
            </>
          </Card>
        </div>
      </div>
      <Modal
        title="Hide Columns"
        open={isColumnModalVisible}
        onCancel={hideColumnModal}
        onOk={hideColumnModal}
      >
        {columns.map((col) => (
          <label
            key={col.dataIndex}
            style={{ display: "block", marginBottom: 8 }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                checked={columnVisibility[col.dataIndex]}
                onChange={() => toggleColumnVisibility(col.dataIndex)}
                disabled={
                  col?.dataIndex === "customerId" ||
                  col?.dataIndex === "actions"
                    ? true
                    : false
                }
              />
              <span style={{ fontSize: "12px" }}>
                {col.dataIndex === "inquiryValue"
                  ? "Inquiry Value If Any"
                  : col.dataIndex === "trialsReportsData"
                  ? "Trials / Demo Reports"
                  : col.dataIndex === "existingSaleData"
                  ? "Saint-gobain existing sale value"
                  : col.dataIndex === "conversionSale"
                  ? "Conversion sale value plan (in lacs)"
                  : col.title}
              </span>
            </div>
          </label>
        ))}
      </Modal>

      {/* add funnel row  */}
      <AddEditFunnelRow
        data={funnelRowsModel}
        setData={setFunnelRowsModel}
        type={funnelRowsModel.type}
      />

      {/* Customer Details  */}
      <ViewCustomerDetailsPopup
        data={viewCustomerDetails}
        setData={setViewCustomerDetails}
      />

      {/* add competition model*/}
      <AddCompetitionForm
        open={addCompetitionModel}
        setOpen={setAddCompetitionModel}
        submitLoading={submitData?.loading}
        formDefine={addCompetitionForm}
        handleSubmit={handleSubmitCompetition}
        rowData={submitData?.row}
      />

      {/* add gobain against competition model*/}
      <AddYourProductForm
        open={addYourProductModel}
        setOpen={setAddYourProductModel}
        submitLoading={submitData?.loading}
        formDefine={addYourProductForm}
        handleSubmit={handleSubmitYourProduct}
        rowData={submitData?.row}
      />

      {/* Total Potential value */}
      <TotalPotentialValueForm
        open={totalPotentialModel}
        setOpen={setTotalPotentialModel}
        submitLoading={submitData?.loading}
        formDefine={totalPotentialForm}
        handleSubmit={handleSubmitPotential}
        rowData={submitData?.row}
        setRowData={setSubmitData}
      />

      {/* Trial reports */}
      <TrialsReportsForm
        open={trialsReportsModel}
        setOpen={setTrialsReportsModel}
        submitLoading={submitData?.loading}
        formDefine={trialsReportsForm}
        handleSubmit={handleSubmitTrialsReports}
        rowData={submitData?.row}
      />

      {/* Existing sale */}
      {/* <ExistingSaleForm
        open={existingSaleModel}
        setOpen={setExisingSaleModel}
        submitLoading={submitData?.loading}
        formDefine={existingSaleForm}
        handleSubmit={handleSubmitExistingSale}
        rowData={submitData?.row}
      /> */}

      {/* Conversion Sale Value */}
      <AddConversionSaleValue
        open={conversionSaleValueModel}
        setOpen={setConversionSaleValueModel}
        submitLoading={submitData?.loading}
        formDefine={conersionSaleValueForm}
        handleSubmit={handleSubmitCoversionSaleValue}
        rowData={submitData?.row}
      />

      {/* Conversion timeline value */}
      <AddTimelineDate
        open={timeLineModel}
        setOpen={setTimeLineModel}
        submitLoading={submitData?.loading}
        formDefine={timelineAddForm}
        handleSubmit={handleSubmitTimeLine}
        rowData={submitData?.row}
      />

      {/* Order value */}
      <AddOrderValuesForm
        open={orderValuesModel}
        setOpen={setOrderValuesModel}
        submitLoading={submitData?.loading}
        formDefine={orderValueForm}
        handleSubmit={handleSubmitOrderValues}
        rowData={submitData?.row}
      />

      {/* remarks */}
      <AddRemarks
        open={remarksModel}
        setOpen={setRemarksModel}
        submitLoading={submitData?.loading}
        formDefine={remarksForm}
        handleSubmit={handleSubmitRemarks}
        rowData={submitData?.row}
      />

      {/* add inquiry */}
      <AddInquiryForm
        open={addInquiryModel}
        setOpen={setAddInquiryModel}
        submitLoading={submitData?.loading}
        formDefine={addInquiryForm}
        handleSubmit={handleSubmitInquiry}
        rowData={submitData?.row}
      />

      {/* carry forward history  */}
      <CarryForwardHistoryTable
        carryHistory={carryHistory}
        setCarryHistory={setCarryHistory}
      />

      {/* progress Stages */}
      <AddProgressStagesFrom
        open={progressStageModel}
        setOpen={setProgressStageModel}
        submitLoading={submitData?.loading}
        formDefine={progressStageForm}
        handleSubmit={handleSubmitProgressStage}
        rowData={submitData?.row}
      />
    </>
  );
};

export default FunnelRowList;

import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import "./success.css";
import LottieAnimation from "../../../utils/shared/lottieAnimation";
import { PATH_DASHBOARD } from "../../../routes/path";
import successData from "../../../assets/lottie/success.json";

const SuccessPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="container">
        <div className="message-box">
          <div className="success-animation">
            <LottieAnimation animationData={successData} />
          </div>
          <h2> Your payment was successfully done </h2>
          <p> Thank you for your payment.</p>
          <Button
            onClick={() => navigate(PATH_DASHBOARD.subscriptions)}
            style={{ marginTop: "20px" }}
            type="primary"
          >
            Go To Subcription
          </Button>
        </div>
      </div>
    </>
  );
};

export default SuccessPage;

import { Modal, Form, Input, Select, Button, Row, Col, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { addEmployeeApi, editEmployeeApi } from "../../redux/apis/dashboardApi";
import {
  employeeActions,
  selectEmployee,
} from "../../redux/slices/employeeSlice";
import { selectLoggedUser } from "../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import uniqueArrayList from "../../utils/UniqueArrayList";
import { helperMethods } from "../../utils/DebounceSearch/helperMethod";

const { Option } = Select;

const AddEmployeeFormModel = ({
  open,
  setOpen,
  employeeList,
  editEmployeeData,
  setEditEmployeeData,
  selectedCustomer,
  setSelectedCustomer,
}) => {
  const [employeeForm] = Form.useForm();

  const dispatch = useDispatch();

  const loggedUser = useSelector(selectLoggedUser);
  const employeeData = useSelector(selectEmployee);

  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPageForReporting, setCurrentPageForReporting] = useState(1);
  const [currentPageForReportee, setCurrentPageForReportee] = useState(1);

  useEffect(() => {
    if (open) {
      dispatch(
        employeeActions.employeeList({
          limit: 10,
          page: 1,
          funnelId: loggedUser?.activeFunnel?._id,
          userId: loggedUser?._id,
          customerId: selectedCustomer.value,
        })
      );
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (employeeData) {
      let newArr = [...employees, ...employeeData?.list];
      let finalArr = uniqueArrayList(newArr, "_id");
      if (finalArr?.length) {
        finalArr = finalArr?.filter(
          (val) => val?._id !== editEmployeeData?.row?._id
        );
        setEmployees(finalArr);
      }
    }
    // eslint-disable-next-line
  }, [employeeData]);

  useEffect(() => {
    if (editEmployeeData?.type === "edit") {
      employeeForm.setFieldsValue({
        employeeName: editEmployeeData?.row?.employeeName,
        designation: editEmployeeData?.row?.designation,
        email: editEmployeeData?.row?.email,
        mobile: editEmployeeData?.row?.mobile,
        department: editEmployeeData?.row?.department,
        responsibility: editEmployeeData?.row?.responsibility,
        reporting: editEmployeeData?.row?.reporting,
        reportee: editEmployeeData?.row?.reportee ?? [],
      });
    }
    // eslint-disable-next-line
  }, [editEmployeeData]);

  const handleCancel = () => {
    employeeForm.resetFields();
    setOpen(false);
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const data = {
        ...values,
        reportee: values?.reportee ?? [],
        reporting: values?.reporting ?? null,
        funnelId: loggedUser?.activeFunnel?._id,
        userId: loggedUser?._id,
        customerId: selectedCustomer.value,
      };

      let res = null;
      if (editEmployeeData?.type === "edit") {
        res = await editEmployeeApi(editEmployeeData?.row?._id, data);
      } else {
        res = await addEmployeeApi(data);
      }
      if (res.data?.success) {
        setEditEmployeeData({ row: null, type: null });
        setLoading(false);
        employeeForm.resetFields();
        setOpen(false);
        message.success(res?.data?.message);
        dispatch(
          employeeActions.employeeList({
            limit: 10,
            page: 1,
            funnelId: loggedUser?.activeFunnel?._id,
            userId: loggedUser?._id,
            customerId: selectedCustomer.value,
          })
        );
      } else {
        setLoading(false);
        setOpen(false);
        message.error(res?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      setOpen(false);
      message.error(error?.response?.data?.message ?? "Something went wrong");
    }
  };

  const employeeInData = (val) => {
    if (val) {
      dispatch(
        employeeActions.employeeSearch({
          searchText: val,
          funnelId: loggedUser?.activeFunnel?._id,
        })
      );
    }
  };

  // eslint-disable-next-line
  const debounceEmployee = useCallback(
    helperMethods.debounce(employeeInData, 500),
    []
  );

  return (
    <>
      <Modal
        open={open}
        onCancel={handleCancel}
        title={
          editEmployeeData?.type === "edit" ? "Edit Employee" : "Add Employee"
        }
        footer={null}
      >
        <Form
          name={employeeForm}
          form={employeeForm}
          onFinish={onFinish}
          layout="vertical"
        >
          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Employee Name"
                name="employeeName"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Employee Name",
                  },
                ]}
              >
                <Input placeholder="Employee Name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Designation"
                name="designation"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Designation",
                  },
                ]}
              >
                <Input placeholder="Designation" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Email",
                  },
                  {
                    type: "email",
                    message: "Please Enter a valid email address",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Mobile"
                name="mobile"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Mobile",
                  },
                  {
                    max: 10,
                    message: "Please enter valid mobile",
                  },
                ]}
              >
                <Input
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  className="hide-arrows"
                  style={{ width: "100%" }}
                  placeholder="Mobile"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Department"
                name="department"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Department",
                  },
                ]}
              >
                <Input placeholder="Department" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Responsibility"
                name="responsibility"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Responsibility",
                  },
                ]}
              >
                <Input placeholder="Responsibility" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Add Reportee" name="reportee">
                <Select
                  // onChange={(e) => debounceEmployee(e)}
                  mode="multiple"
                  placeholder="Add Reportee"
                  style={{ width: "100%" }}
                  allowClear
                  onPopupScroll={(e) => {
                    const target = e.target;
                    if (
                      target.scrollTop + target.clientHeight ===
                      target.scrollHeight
                    ) {
                      if (employeeData?.isNext) {
                        dispatch(
                          employeeActions.employeeList({
                            limit: 10,
                            page: currentPageForReportee + 1,
                            funnelId: loggedUser?.activeFunnel?._id,
                            userId: loggedUser?._id,
                            customerId: selectedCustomer.value,
                          })
                        );
                        setCurrentPageForReportee(currentPageForReportee + 1);
                      }
                    }
                  }}
                  // onClear={() => debounceEmployee("")}
                  showSearch
                >
                  {employees.map((employee, i) => (
                    <Option key={i} value={employee?.employeeName}>
                      {employee.employeeName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Reporting To" name="reporting">
                <Select
                  // onChange={(e) => debounceEmployee(e)}
                  placeholder="Reporting To"
                  style={{ width: "100%" }}
                  allowClear
                  onPopupScroll={(e) => {
                    const target = e.target;
                    if (
                      target.scrollTop + target.clientHeight ===
                      target.scrollHeight
                    ) {
                      if (employeeData?.isNext) {
                        dispatch(
                          employeeActions.employeeList({
                            limit: 10,
                            page: currentPageForReporting + 1,
                            funnelId: loggedUser?.activeFunnel?._id,
                            userId: loggedUser?._id,
                            customerId: selectedCustomer.value,
                          })
                        );
                        setCurrentPageForReporting(currentPageForReporting + 1);
                      }
                    }
                  }}
                  // onClear={() => debounceEmployee("")}
                  showSearch
                >
                  {employees.map((employee, i) => (
                    <Option key={i} value={employee?.employeeName}>
                      {employee.employeeName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <Button onClick={handleCancel} htmlType="reset" type="default">
              Cancel
            </Button>
            <Button loading={loading} htmlType="submit" type="primary">
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddEmployeeFormModel;
